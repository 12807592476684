import React, { useState } from "react";
import { Search } from "@heathmont/moon-core-tw";

const EmployeesHeader = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  return (
    <div className="sm:px-7 px-4 bg-primary">
      <div className="flex items-center py-[23px]  -mx-[16px]">
        <div className="w-1/4 px-[16px]">
          <div>
            <h3 className="text-[32px] font-semibold -tracking-[0.5px] text-white mb-8px">
              Employees
            </h3>
            <p className="text-white text-[16px] ">
              List of your all hospital employees
            </p>
          </div>
        </div>
        <div className="w-3/4  px-[16px]">
          {/* <Search
            onChangeSearch={setSearch}
            onChangeOpen={setOpen}
            search={search}
            isOpen={open}
          >
            <Search.Input>
              <Search.Input.Icon />
              <Search.Input.Input
                placeholder="search"
                className="text-moon-16 text-trunks h-[56px]"
              />
            </Search.Input>

            <Search.Transition>
              <Search.Result>Search Results</Search.Result>
            </Search.Transition>
          </Search> */}
        </div>
      </div>
    </div>
  );
};

export default EmployeesHeader;
