import React, { useState, JSX } from "react";

import { Checkbox, IconButton, Button } from "@heathmont/moon-core-tw";

import {
  ControlsPlus,
  GenericAlarmRound,
  GenericBlock,
  GenericDelete,
  GenericEdit,
} from "@heathmont/moon-icons";
import { Link } from "react-router-dom";

import ConfirmationModal from "./ConfirmationModal";
import { IHospital } from "../../../../../types/super-admin-interfaces/IHospital.interface";
import { Other3DotsHorizontal } from "@heathmont/moon-icons-tw";

type Column = {
  Header: () => JSX.Element;

  accessor: keyof DataRow; // Using DataRow type here
};

type DataRow = {
  check: JSX.Element;

  role: string;

  branch: JSX.Element;

  action: JSX.Element;

  // ... other properties
};

const HospitalListing = ({
  openBranch,

  handleClick,

  hospitalData,

  getHospitalId,
  handleModal,
  deActivateHospitalData,
  activateHospitalData,

  getHospitalID,
}: any) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeConfirmModal = () => setIsOpenConfirm(false);
  const [hospitalDeactivate, setHospitalDeactivate] = useState<IHospital>();
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const openConfirmationModal = (data: any) => {
    setConfirmationData(data);
    setIsOpenConfirm(true);
  };

  const columnsInitial: Column[] = [
    {
      Header: () => <div className="font-bold">Hospital Name</div>,

      accessor: "role",
    },

    {
      Header: () => <div className="font-bold">Branches</div>,

      accessor: "branch",
    },

    {
      Header: () => <div></div>,

      accessor: "action",
    },

    // ... other columns
  ];

  const data: DataRow[] = hospitalData
    ? hospitalData?.hospitals.map((hospital: IHospital) => ({
        role: hospital.name,
        status: hospital.isActive,
        branch: (
          <div>
            <div className="flex items-center gap-3">
              <span>{hospital.isActive}</span>
              {hospital?.numberOfBranches === 0 && hospital?.isActive ? (
                <Link
                  to="#"
                  className="text-primary inline-flex gap-[5px] items-center"
                  onClick={() => {
                    openBranch();
                    getHospitalId(hospital);
                  }}
                >
                  <ControlsPlus /> Add Branch
                </Link>
              ) : hospital?.isActive ? (
                <>
                  <span className="text-gray-400">
                    {" "}
                    {hospital?.numberOfBranches}
                  </span>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      handleClick();
                      getHospitalId(hospital);
                    }}
                    icon={<Other3DotsHorizontal />}
                    data-testid="button"
                  />
                </>
              ) : (
                <>
                  <GenericAlarmRound />
                  <span>Activate to Manage Branch</span>
                </>
              )}
            </div>
          </div>
        ),

        action: (
          <div className="flex gap-1 justify-end">
            {hospital?.isActive ? (
              <>
                <IconButton
                  variant="ghost"
                  size="sm"
                  icon={<GenericEdit />}
                  onClick={() => {
                    handleModal(hospital);

                    getHospitalID(hospital._id);
                  }}
                />

                <IconButton
                  animation="error"
                  size="sm"
                  icon={<GenericBlock />}
                  data-testid="error"
                  onClick={() => {
                    openConfirmationModal({
                      title: "Confirm Hospital De-Activate",
                      actionFor: "Hospital",
                      message: (
                        <p className="text-moon-18 mb-[28px]">
                          {" "}
                          The Hospital{" "}
                          <strong className="font-semibold ">
                            {hospital?.name}
                          </strong>
                          <br /> and all its related services will be
                          de-activated
                        </p>
                      ),
                      confirmAction: "De-Activate",
                      id: {
                        hospitalId: hospital?._id,
                      },
                    });

                    // setIsOpenConfirm(true);
                    // setHospitalDeactivate(hospital);
                  }}
                />
              </>
            ) : (
              <Button
                variant="primary"
                className="bg-hit"
                size="xs"
                onClick={() => {
                  activateHospitalData(hospital?._id);
                }}
              >
                Activate
              </Button>
            )}
          </div>
        ),
      }))
    : [];
  console.log("hosp data", data[0]);
  return (
    <div className="tableResponsive table-hospital bg-[#F5F5F5] p-[8px] rounded-[8px] ">
      <table className="w-full">
        <thead>
          <tr>
            {columnsInitial.map((column, columnIndex) => (
              <th
                className="text-left 2xl:text-moon-16 taxt-sm text-[#000] font-normal"
                key={columnIndex}
              >
                {column.Header()}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((rowData, rowIndex) => {
            console.log("Row Data", rowData);
            return (
              <React.Fragment key={rowIndex}>
                <tr>
                  {columnsInitial.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`${
                        rowData?.branch?.props?.children?.props?.children[0]
                          ?.props?.children
                          ? ""
                          : "bg-[#EDEDED!important]"
                      } 2xl:text-moon-16 taxt-sm
                  `}
                    >
                      {(rowData as any)[column.accessor]}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <ConfirmationModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={closeConfirmModal}
        confirmationData={confirmationData}
        onConfirm={() =>
          deActivateHospitalData(confirmationData?.id?.hospitalId)
        }
        // deActivateHospitalData={deActivateHospitalData}
        // hospitalData={hospitalDeactivate as IHospital}
      />
    </div>
  );
};

export default HospitalListing;
