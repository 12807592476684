import { Search } from "@heathmont/moon-core-tw";
import React, { useState } from "react";

const NeedSearchFilter = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  return (
    <div className="grid grid-cols-2 bg-white rounded-[0.5rem]">
      <div className="h-full">
        <Search
          onChangeSearch={setSearch}
          onChangeOpen={setOpen}
          search={"search"}
          isOpen={open}
          className="rounded-tr-[0] rounded-br-[0] h-[56px]"
        >
          <Search.Input>
            <Search.Input.Icon />
            <Search.Input.Input
              className="text-moon-14 text-trunks  rounded-tr-[0] rounded-br-[0]  h-[56px]"
            />
          </Search.Input>
          <Search.Transition>
            <Search.Result>{/* Search Results */}</Search.Result>
          </Search.Transition>
        </Search>
      </div>
      <div className="p-[6px_16px] border-l-transparent border border-beerus">
        <span className="block text-trunks text-moon-12 leading-1">Month</span>
        <h4 className="2xl:text-moon-16 text-moon-14 ">November 2022</h4>
      </div>
    </div>
  );
};

export default NeedSearchFilter;
