import React, { useEffect, useState } from "react";
import { Avatar } from "@heathmont/moon-core-tw";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import DrawerEmployee from "../../Drawers/employee-detail/DrawerEmployee";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getEmployeeProfileData } from "../../../../services/nurse-manager-services/employee.service";
import {
  IEmployeeData,
  IShift,
} from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
import { IEmployeeDetails } from "../../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";
import { getBorderColor } from "../../../Colors/colorSetting";
const employees = [
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["nurse"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["Supervisor"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["nurse"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["Supervisor"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["Supervisor"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["nurse"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["HSA"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["leader"],
  },
  {
    name: "Eleanor Pena",
    avatarImageUrl: "images/Avatar.png",
    shifts: [
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Night",
      "Day",
      "RO",
      "AL",
      "Night",
      "",
      "Day",
      "Night",
      "",
      "RO",
      "Night",
      "Day",
      "RO",
      "Day",
      "Night",
      "Day",
      "RO",
      "",
      "AL",
      "Night",
      "",
      "RO",
      "Day",
      "Night",
      "Day",
      "Day",
    ],
    roles: ["supervisor"],
  },
];

interface Props {
  data: IEmployeeData[];
  month: number;
  year: string;
}

const EmployeeShiftMonthly: React.FC<Props> = ({ data, month, year }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentDate = new Date();
  // const [employeeData, setEmployeeData] = useState<any>();
  const [employeeInfo, setEmployeeInfo] = useState<IEmployeeDetails>();
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const { date } = useSelector((state: RootState) => state.time);
  const LeaveTypeColors: any = {
    AL: {
      bgColor: "bg-amber-100",
      textColor: "text-amber-700",
      label: "AL",
      name: "Annual Leave",
    },
    RO: {
      bgColor: "bg-cyan-100",
      textColor: "text-cyan-600",
      label: "RO",
      name: "Request Off",
    },
    CL: {
      bgColor: "bg-blue-100",
      textColor: "text-blue-600",
      label: "CL",
      name: "Compensation Leave",
    },
    EL: {
      bgColor: "bg-red-100",
      textColor: "text-red-600",
      label: "EL",
      name: "Emergency Leave",
    },
    ML: {
      bgColor: "bg-pink-100",
      textColor: "text-pink-600",
      label: "ML",
      name: "Maternity Leave",
    },
    PL: {
      bgColor: "bg-green-100",
      textColor: "text-green-600",
      label: "PL",
      name: "Paternity Leave",
    },
    // SL: {
    //   bgColor: "bg-yellow-100",
    //   textColor: "text-yellow-600",
    //   label: "SL",
    //   name: "Study Leave",
    // },
    UL: {
      bgColor: "bg-purple-100",
      textColor: "text-purple-600",
      label: "UL",
      name: "Unpaid Leave",
    },
    SL: {
      bgColor: "bg-gray-100",
      textColor: "text-gray-600",
      label: "SL",
      name: "Sick Leave",
    },
    // Add more leave types as needed
  };

  const ShiftIcons = {
    sun: "uil:sun",
    moon: "moon-icon", // Replace with your moon icon
  };
  const getShiftClassNames = (shift: any) => {
    const leaveType = shift.leaveType;
    const isDayShift = shift.isDayShift;
    const isNightShift = shift.isNightShift;
    const isOnTimeOff = shift.isOnTimeOff;

    if (leaveType) {
      const { bgColor, textColor, label } = LeaveTypeColors[leaveType] || {};
      return `${bgColor || ""} ${textColor || ""} font-semibold`;
    }

    if (isDayShift && selectedShift !== "moon" && isOnTimeOff) {
      return `text-moon-24 text-krillin`;
    }

    if (isNightShift && selectedShift !== "sun" && isOnTimeOff) {
      return `text-moon-24`;
    }

    if (isOnTimeOff && !isDayShift && !isNightShift) {
      const { bgColor, textColor, label } = LeaveTypeColors.RO || {};
      return `${bgColor || ""} ${textColor || ""} font-semibold`;
    }

    return "";
  };
  // const filters = () => {
  //   const newData = data?.map((cData: any) => {
  //     const nightShifts = cData.shifts.filter(
  //       (fil: any) => fil?.isNightShift == true,
  //     );
  //     return { ...cData, shifts: nightShifts };
  //   });
  //   return newData;
  // };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const User = useSelector((state: RootState) => state.auth.User?.designation);

  return (
    <>
      {data?.map((employee: IEmployeeData, index: any) => (
        <div className="grid grid-cols-6 w-full cursor-pointer" key={index}>
          <div className="">
            <div className="h-[43px] 2xl:px-[30px] px-3 pe-[14px] py-2 border-b border-r flex items-center justify-between">
              <div className="flex items-center">
                <Avatar
                  size="xs"
                  className={`rounded-full border-2  ${getBorderColor(
                    employee?.designation
                  )} overflow-hidden`}
                  // imageUrl={employee?.avatarImageUrl}
                />
                <div className="flex-1 2xl:text-moon-14 text-moon-12  transition-colors pl-[10px] line-clamp-1 truncate 2xl:max-w-[200px] max-w-[150px]">
                  {employee?.employeeName}
                </div>
              </div>
              <div className="text-[12px] bg-[#F2F5F6] rounded py-0.5 2xl:px-1.5 px-1">
                {employee?.totalShiftsAligned}
              </div>
            </div>
          </div>
          <div className="col-span-5">
            <div className={`h-[43px]  flex `} key={index}>
              {employee?.shifts?.map((shift: IShift, shiftIndex: number) => {
                return (
                  <div
                    className={`${
                      shift.day === "Saturday" || shift.day === "Sunday"
                        ? "bg-gray-200 border-sky2"
                        : currentDate.getDate() ===
                            new Date(shift.date).getDate() &&
                          currentDate.getMonth() ===
                            new Date(shift.date).getMonth()
                        ? "bg-primary border-sky2"
                        : "border-beerus"
                    } flex-1 border-b border-r border-s-0 h-full py-2 text-[14px]  justify-center inline-flex items-center ${getShiftClassNames(
                      shift
                    )}`}
                    key={shiftIndex}
                  >
                    {shift?.leaveType && (
                      <span className="font-semibold">
                        {LeaveTypeColors[shift?.leaveType]?.label}
                      </span>
                    )}
                    {shift?.isDayShift && selectedShift !== "moon" && (
                      <i className={`text-moon-24 text-krillin `}>
                        <Icon icon={ShiftIcons.sun} />
                      </i>
                    )}
                    {shift?.isNightShift && selectedShift !== "sun" && (
                      <i className="text-moon-24">
                        <OtherMoon />
                      </i>
                    )}
                    {!shift?.isOnLeave &&
                      shift?.isOnTimeOff &&
                      !shift?.isDayShift &&
                      !shift?.isNightShift && (
                        <span className="font-semibold">
                          {LeaveTypeColors.RO.label}
                        </span>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )) || null}

      <DrawerEmployee
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        // employeeData={employeeData}
        employeeInfo={employeeInfo as IEmployeeDetails}
      />
    </>
  );
};

export default EmployeeShiftMonthly;

{
  /* <div
                className={`flex-1 h-[43px] border border-beerus border-s-0 justify-center inline-flex items-center`}
                key={index}
              >
                {employee.shifts.map((shift: any, shiftIndex: number) => (
                  <div
                    className={`flex-1 h-full  py-2 text-[14px] border-r border-beerus border-s-0 justify-center inline-flex items-center ${
                      shift.leaveType === "AL"
                        ? "bg-amber-100 text-amber-600"
                        : shift?.isOnTimeOff &&
                          !shift?.isNightShift &&
                          !shift?.isDayShift //shift.leaveType === "RO"
                        ? "bg-cyan-50 text-cyan-600"
                        : ""
                    }`}
                    key={shiftIndex}
                  >
                    {shift?.leaveType === "AL" ? (
                      <span className="font-semibold">{shift?.leaveType}</span>
                    ) : shift?.leaveType == "ML" ? (
                      <span className="font-semibold">{shift?.leaveType}</span>
                    ) : shift?.isDayShift ? (
                      selectedShift !== "moon" ? (
                        <i className="text-moon-24 text-krillin ">
                          <Icon icon="uil:sun" />
                        </i>
                      ) : null
                    ) : shift?.isNightShift ? (
                      selectedShift !== "sun" ? (
                        <i className="text-moon-24">
                          <OtherMoon />
                        </i>
                      ) : null
                    ) : shift?.isOnTimeOff ? (
                      <span className="font-semibold">{"RO"}</span>
                    ) : null}
                  </div>
                ))}
              </div> */
}
