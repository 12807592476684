import React, { useState } from "react";
import NeedStats from "../Widgets/cards/stats/NeedStats";
import moment from "moment";
const data = [
  {
    title: "Allocation required",
    value: { name: "Aug" },
    statsNumber: 1,
    options: [
      { name: "Sep", value: 1 },
      { name: "Oct", value: 2 },
      { name: "Dec", value: 3 },
    ],
  },
  {
    title: "Provided",
    value: { name: "Sep" },
    statsNumber: 6,
    options: [
      { name: "Sep", value: 1 },
      { name: "Oct", value: 2 },
      { name: "Dec", value: 3 },
    ],
  },
  {
    title: "Total Needs",
    value: { name: "Week" },
    statsNumber: 21,
    options: [
      { name: "Week 1", value: 1 },
      { name: "Week 2", value: 2 },
      { name: "Week 3", value: 3 },
    ],
  },
];

interface props {
  bgColor: string;
  data?: any;
  selectedMonthOption: any;
  setSelectedMonthOption: any;
  title?: string;
  subTitle?: string;
}

// const [selectedMonthOption, setSelectedMonthOption] = useState<string>(
//   moment(data?.records[0]?.createdAt).format("MMM"),
// );
const RequestHeader = ({
  bgColor,
  data,
  selectedMonthOption,
  setSelectedMonthOption,
  title,
  subTitle,
}: props) => {
  const month: any = Array.from({ length: 12 }, (_, index) => ({
    name: `${moment().month(index).format("MMMM")}`,
    monthName: moment().month(index).format("MMMM"),
    label: index + 1,
  }));
  function convertLeavesData() {
    const currentMonthROLeaves = data?.currentMonthROLeaves;
    const currentMonthTotalLeaves = data?.currentMonthTotalLeaves;
    const month = moment(data?.records[0]?.createdAt).format("MMM"); // You can extract the month dynamically if needed
    const leavesData = [
      {
        title: "Month RO Leaves",
        value: currentMonthROLeaves,
        month: month,
      },
      {
        title: "Month Total Leaves",
        value: currentMonthTotalLeaves,
        month: month,
      },
    ];

    return leavesData;
  }

  const convertedData = data ? convertLeavesData() : [];

  return (
    <div className="sm:px-7 px-4 bg-hit">
      <div className="grid lg:grid-cols-12 gap-4 items-center py-[23px] min-h-[118px] ">
        <div className="2xl:col-span-2 lg:col-span-4">
          <div>
            <h3 className="2xl:text-[32px] text-[28px] font-semibold -tracking-[0.5px] text-white  mb-8px">
              {title ? title : "Requests"}
            </h3>
            <p className="text-white 2xl:text-[16px] text-[14px] ">
              {subTitle ? subTitle : "All requests for leaves"}
            </p>
          </div>
        </div>
        <div className="2xl:col-span-10 lg:col-span-8">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-[15px]">
            {convertedData?.map((item, index) => (
              <div>
                <NeedStats
                  key={index}
                  title={item?.title}
                  value={selectedMonthOption}
                  statsNumber={item?.value}
                  options={month}
                  bgColor="bg-[rgb(54,69,90,0.15)]"
                  textColor="text-white"
                  setSelectedMonthOption={setSelectedMonthOption}
                  selectedMonthOption={selectedMonthOption}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestHeader;
