import { io, Socket } from "socket.io-client";
class NotificationSocketClass {
  public socket: Socket | null = null;

  initializeConnection = (
    apiBaseUrl: string,
    userId: string,
    designation: string
  ) => {
    this.socket = io(
      apiBaseUrl
      // {        userId:userId        }
    );
    this.socket.connect();
    this.provideUserId(userId);
    // this.socketListner(designation);
    console.log("connection");
  };

  socketListner = (designation: string) => {
    if (this.socket) {
      this.socket.on("connect", () => {
        console.info("User Presence socket is connected successfully.");
      });

      this.socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          this.socket && this.socket.connect();
        }
      });

      // switch (designation) {
      //   case "nurse manager":
      //     notificationSocket.socket?.on(
      //       "respondScheduleRequest",
      //       (resOfScheduleReq) => {
      //         console.log(" check noti for nurse manager");
      //         store.dispatch(setSnackBar("notification"));
      //         store.dispatch(setToastMessage(resOfScheduleReq?.message));
      //         if (!resOfScheduleReq?.message?.includes("declined"))
      //           store.dispatch(setNotifications({ updateData: true }));
      //         fetchUnreadNotification();
      //         console.log("nurse manager ");
      //       }
      //     );
      //     return () => {
      //       notificationSocket.socket?.off("respondScheduleRequest");
      //     };
      //   case "nurse director":
      //     notificationSocket.socket?.on(
      //       "scheduleSubmitted",
      //       (submitToApprove) => {
      //         store.dispatch(setSnackBar("notification"));
      //         store.dispatch(setToastMessage(submitToApprove?.message));
      //         fetchUnreadNotification();
      //         store.dispatch(setNotifications({ updateData: true }));
      //         console.log("nurse director ");
      //       }
      //     );
      //     return () => {
      //       notificationSocket.socket?.off("scheduleSubmitted");
      //     };

      //   default:
      //     return null;
      // }
    }
  };

  provideUserId(userId: string) {
    if (this.socket) {
      this.socket.emit("joinRoom", userId);
      console.log("id sent");
    }
  }

  disconnectSocket() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export const notificationSocket = new NotificationSocketClass();
