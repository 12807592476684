import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface Props {
  month: number;
  year: string;
}

const MonthDays: React.FC<Props> = ({ month, year }) => {
  const { date } = useSelector((state: RootState) => state.time);
  const currentMoment = moment();
  const selectedMonth = month || currentMoment.month() + 1;
  const selectedYear = year || currentMoment.year().toString();

  const renderCalendarDates = () => {
    const selectedMonthIndex = moment()
      .year(+selectedYear)
      .month(selectedMonth - 1)
      .startOf("month");
    const daysInMonth = selectedMonthIndex.daysInMonth();

    const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
    const calendarDates = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDay = selectedMonthIndex.clone().date(day);
      const weekDay = currentDay.day();
      const isWeekend = weekDay === 0 || weekDay === 6;
      const weekName = weekDays[weekDay];

      const isCurrentDay =
        currentMoment.date() === day &&
        currentMoment.month() === selectedMonth - 1 &&
        currentMoment.year() === +selectedYear;

      const dateClassName = isCurrentDay
        ? "bg-primary text-white"
        : isWeekend
        ? "bg-gray-200 text-black"
        : "";

      calendarDates.push(
        <div
          key={day}
          className={`flex-1 h-[64px] border-r border-b border-sky2 2xl:px-3 px-1 py-2 inline-flex justify-center items-center ${dateClassName}`}
        >
          <div className="text-moon-14 font-semibold transition-colors">
            {weekName}
          </div>
        </div>
      );
    }

    return calendarDates;
  };

  return <div className="flex  w-full">{renderCalendarDates()}</div>;
};

export default MonthDays;
