import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import moment from "moment";

interface Props {
  month: string;
  year: string;
}
const TopbarCalendar: React.FC<Props> = ({ month, year }) => {
  const { date } = useSelector((state: RootState) => state.time);
  return (
    <div className="h-[64px] px-[30px] py-2  border-b border-sky2 border-r ">
      <h3 className="text-moon-18 font-medium transition-colors">
        {/* {moment({ month: date?.currentMonth - 1 }).format("MMMM")} */}
        {month}
      </h3>
      {/* <p className="text-moon-14 transition-colors">{date?.year}</p> */}
      <p className="text-moon-14 transition-colors">{year}</p>
    </div>
  );
};

export default TopbarCalendar;
