import React, { useState } from "react";
import { flexRender } from "@tanstack/react-table";
// import { ITableDataResponse } from "../../types/interfaces/ITableData.interface";
import { generatePageNumbers } from "../../../utils/generatePageNumbers";
import { IconButton } from "@heathmont/moon-core-tw";
import {
  ArrowsSorting,
  ArrowsUp,
  ControlsChevronLeftSmall,
  ControlsChevronRightSmall,
} from "@heathmont/moon-icons";
import RequestModal from "../Widgets/modal/RequestModal";
import SimpleBar from "simplebar-react";
interface EmployeeRecord {
  _id: string;
  employeeId: string;
  employeeName: string;
  designation: string;
  requestType: string;
  requestStatus: string;
  createdAt: string;
  details: number;
}
interface RecordsResponse {
  currentMonthROLeaves: number;
  currentMonthTotalLeaves: number;
  records: EmployeeRecord[];
  totalRecords: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

interface Schedule {
  _id: string;
  status: string;
  month: number;
  year: number;
  section: string;
  scheduledBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  sectionName: string;
}

interface SectionsData {
  currentPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  limit: number;
  nextPage: number | null;
  prevPage: number | null;
  schedules: Schedule[]; // Assuming the Schedule interface from the previous response
  totalPages: number;
  totalRecords: number;
}

// interface Props {
//   table: any;
//   tableData: RecordsResponse | SectionsData;
//   handlePageChange: (data: number) => void;
//   handleRowClick: (data: any) => any; // Add this prop
// }

interface Props {
  table: any;
  tableData: any;
  handlePageChange: (data: number) => void;
  handleRowClick: (data: any) => any; // Add this prop
}

// const handleRowClick = (row: any) => {
//   // You can access the row's data here
//   console.log(row.original);
// };

const Table: React.FC<Props> = ({
  table,
  tableData,
  handlePageChange,
  handleRowClick,
}) => {
  if (!tableData || !tableData.records || !tableData.records.length) {
    return (
      <div className="sectionsvg flex items-center justify-center text-center">
        {/* You can render a loading indicator or an error message here */}
        {/* Loading or No Data Available */}
        <img
          src={"images/no-schedule-to-approve.svg"}
          alt="No schedule to approve"
        />
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <SimpleBar className="2xl:h-[calc(100dvh-353px)] h-[calc(100dvh-353px)] relative z-[0] overflow-y-auto">
        <table className="coustomstyl w-full border-spacing-y-1 border-separate px-2">
          <thead>
            {table
              ?.getHeaderGroups()
              ?.map((headerGroup: any, index: number) => (
                <tr key={index}>
                  {headerGroup.headers.map((header: any, index: number) => (
                    <th
                      key={index}
                      colSpan={header.colSpan}
                      className="text-left px-4 sticky top-0 bg-slate-100 z-5 2xl:text-base text-sm"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none flex justify-between"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getIsSorted() == "desc" ? (
                            <IconButton
                              variant="ghost"
                              className={`p-0 h-[24px] rotate-180`}
                              icon={<ArrowsUp />}
                            />
                          ) : header.column.getIsSorted() == "asc" ? (
                            <IconButton
                              variant="ghost"
                              className={`p-0 h-[24px]`}
                              icon={<ArrowsUp />}
                            />
                          ) : header.column.columnDef.enableSorting !==
                            false ? (
                            // <>⬍{console.log("A", header.column)}</>
                            <IconButton
                              variant="ghost"
                              className={`p-0 h-[24px]`}
                              icon={<ArrowsSorting />}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody className="">
            {table?.getRowModel()?.rows?.map((row: any, index: number) => (
              <tr
                key={row?.id + index}
                onClick={() => handleRowClick(row)}
                className="bg-white p-2"
              >
                {row?.getVisibleCells()?.map((cell: any, idx: number) => (
                  <td
                    key={cell.id + idx}
                    className="text-left py-2 px-4 text-moon-14 "
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table
              ?.getFooterGroups()
              ?.map((footerGroup: any, index: number) => (
                <tr key={index}>
                  {footerGroup?.headers?.map((header: any, index: number) => (
                    <th key={index}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
          </tfoot>
        </table>
        {/*  */}
        
      </SimpleBar>
      <div className="flex items-center p-4">
        <div className="w-1/3">
          <div className="flex text-left text-moon-12">
            <div>Showing </div> &nbsp;
            {tableData?.currentPage * Number(tableData?.limit) -
              Number(tableData?.limit) +
              1}{" "}
            -{" "}
            {tableData?.currentPage * Number(tableData?.limit) -
              Number(tableData?.limit) +
              Number(tableData?.records?.length)}
            &nbsp;
            <span className="text-[#999CA0]">
              of {Number(tableData?.totalRecords)}
            </span>
          </div>
        </div>
        <div className="w-2/3">
          <div className="flex items-center justify-between">
            <IconButton
              icon={<ControlsChevronLeftSmall className="rtl:rotate-180" />}
              variant="secondary"
              size="sm"
              onClick={() => {
                handlePageChange(tableData?.currentPage - 1);
              }}
              disabled={!tableData?.hasPrevPage}
              aria-label="Previous"
            />
            <div className="flex items-center gap-2">
              {generatePageNumbers(tableData).map((pageNumber, index) => (
                <React.Fragment key={index}>
                  {pageNumber === "..." ? (
                    <span className="mx-1">{pageNumber}</span>
                  ) : (
                    <button
                      className={`flex items-center justify-center cursor-pointer w-8 h-8 rounded-moon-s-sm focus:outline-none transition-colors font-medium ${
                        pageNumber === tableData?.currentPage
                          ? "bg-primary text-white"
                          : ""
                      }`}
                      onClick={() =>
                        typeof pageNumber === "number" &&
                        handlePageChange(pageNumber)
                      }
                    >
                      {pageNumber}
                    </button>
                  )}
                </React.Fragment>
              ))}
            </div>

            <IconButton
              icon={<ControlsChevronRightSmall className="rtl:rotate-180" />}
              variant="secondary"
              size="sm"
              onClick={() => {
                handlePageChange(tableData?.currentPage + 1);
              }}
              disabled={!tableData?.hasNextPage}
              aria-label="Previous"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
