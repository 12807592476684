import React from 'react';
import { Link } from 'react-router-dom';

interface FilterTabsProps {
    links: { to: string; label: string; value: string }[];
    selectedButton: string;
    handleButtonClick: (button: string) => void;
}

const FilterTabs: React.FC<FilterTabsProps> = ({ links, selectedButton, handleButtonClick }) => {
    return (
        <div className="flex items-center justify-center gap-1 p-1 bg-goku rounded-moon-s-sm bg-gray-200 ml-auto">
            {links.map((link) => (
                <Link
                    key={link.value}
                    to={link.to}
                    className={`2xl:px-3 px-2 py-1 gap-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${selectedButton === link.value ? "bg-gohan text-slate-900" : "text-trunks"
                        }`}
                    onClick={() => handleButtonClick(link.value)}
                >
                    {link.label}
                </Link>
            ))}
        </div>
    );
}

export default FilterTabs;
