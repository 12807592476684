import React, { useEffect, useMemo } from "react";
import EmployeesHeader from "../../../../Components/nurse-manager/employees/EmployeesHeader";
import FilterSideBar from "../../../../Components/nurse-manager/employees/FilterSideBar";
import FilterEmployee from "../../../../Components/nurse-manager/employees/FilterEmployee";
import EmployeesTable from "../../../../Components/nurse-manager/employees/EmployeesTable";
import { useState } from "react";
import SimpleBar from "simplebar-react";
import { getEmployeeViewForNurseManager } from "../../../../services/nurse-manager-services/nursemanager.service";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../../../store/store";
import {
  setDate,
  setSelectedShift,
} from "../../../../features/nurse-manager/Time Slice/timeSlice";
import {
  IEmployee,
  IEmployeeData,
} from "../../../../types/nurse-manager-interfaces/IEmployeeTableResponse.interface";
import FooterCalendar from "../../../../Components/nurse-manager/Calendar/monthly/FooterCalendar/FooterCalendar";
import { Icon } from "@iconify/react";

interface SubItem {
  id: number;
  label: string;
  name: string;
  isSelected: boolean;
}

interface SidebarItem {
  id: number;
  label: string;
  isOpen: boolean;
  subItems: SubItem[];
}

const Employees = () => {
  const { date } = useSelector((state: RootState) => state.time);
  const [tableData, setTableData] = useState<IEmployeeData>(
    {} as IEmployeeData
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [sidebarData, setSidebarData] = useState<SidebarItem[]>([
    {
      id: 1,
      label: "Name",
      isOpen: true,
      subItems: [
        { id: 1, label: "A_Z", name: "A-Z", isSelected: false },
        { id: 2, label: "Z_A", name: "Z-A", isSelected: false },
      ],
    },
    {
      id: 2,
      label: "Availability",
      isOpen: true,
      subItems: [
        { id: 3, label: "available", name: "Available", isSelected: false },
        {
          id: 4,
          label: "notAvailable",
          name: "Not Available",
          isSelected: false,
        },
        // { id: 3, label: "Paternity Leave", isSelected: false },
        // { id: 4, label: "Time in Lieu", isSelected: false },
        // { id: 5, label: "Request Off", isSelected: false },
        // { id: 6, label: "Study Leave", isSelected: false },
        // { id: 7, label: "Compensation Leave", isSelected: false },
      ],
    },
    {
      id: 3,
      label: "Designation",
      isOpen: true,
      subItems: [
        // {
        //   id: 9,
        //   label: "nurseManager",
        //   name: "NURSE MANAGER",
        //   isSelected: false,
        // },
        {
          id: 2,
          label: "seniorNurse",
          name: "SENIOR NURSE",
          isSelected: false,
        },
        { id: 5, label: "Nurse", name: "NURSE", isSelected: false },
        {
          id: 6,
          label: "HCA",
          name: "HCA",
          isSelected: false,
        },
        // { id: 4, label: "Time in Lieu", isSelected: false },
        // { id: 5, label: "Request Off", isSelected: false },
        // { id: 6, label: "Study Leave", isSelected: false },
        // { id: 7, label: "Compensation Leave", isSelected: false },
      ],
    },
  ]);
  const dispatch = useDispatch();
  const [fetchData, setFetchData] = useState(false);
  const [filters, setFilters] = useState(false);
  const fetchEmployeeViewForNurseManager = async () => {
    const selectedItem: SubItem[] = sidebarData?.flatMap(
      (menuItem: SidebarItem) =>
        menuItem?.subItems?.filter((subItem: SubItem) => subItem?.isSelected)
    );
    const extractedData = selectedItem?.reduce((acc: any, item: any) => {
      acc[item.label] = item.isSelected;
      return acc;
    }, {});
    const res: any = await getEmployeeViewForNurseManager({
      month: date.currentMonth,
      year: date.year,
      ...extractedData,
      search: searchQuery,
    });
    if (res?.res) {
      setTableData(res?.res);
    }
  };
  useEffect(() => {
    const currentDate = moment();
    const dateInfo = moment({
      month: currentDate.month(),
      year: currentDate.year(),
      day: currentDate.day(),
    });

    // console.log("MONTH", dateInfo.format("MMMM"), dateInfo.format("DD"));
    dispatch(
      setDate({
        day: +dateInfo.format("DD"),
        month: dateInfo.format("MMMM"),
        year: +dateInfo.format("YYYY"),
        currentMonth: +dateInfo.format("MM"),
      })
    );
    // if (!fetchData) {
    //   fetchEmployeeViewForNurseManager();
    // }
    setFetchData(true);
  }, []);

  useEffect(() => {
    if (filters && fetchData) {
      fetchEmployeeViewForNurseManager();
    }
    // setFetchData(true);
  }, [sidebarData, fetchData, date]);

  const handleSidebarDataUpdate = (data: SidebarItem[]) => {
    console.log("DATA", data);
    setSidebarData(data);
    setFilters(true);
  };

  // Debounce the search term
  useEffect(() => {
    if (fetchData) {
      const debounceTimeout = setTimeout(() => {
        // console.log("CALL THIS FUNC");
        fetchEmployeeViewForNurseManager(); // Trigger the fetch with the debounced search term
      }, 1000); // Adjust the debounce delay as needed
      return () => clearTimeout(debounceTimeout);
    }
  }, [searchQuery]);

  // console.log("search", searchResults);
  return (
    <>
      <div className="bg-slate-100">
        <EmployeesHeader />
        <div className="grid grid-cols-12 flex-wrap">
         
        <div className="2xl:col-span-2 col-span-3">
            <div className="border-r border-beerus  bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
              <div className="bg-whitept-[30px] 2xl:p-[30px] p-5">
                <h3
                  className={`text-moon-20 font-semibold ${
                    tableData?.employees?.length === 0
                      ? "pointer-events-none"
                      : ""
                  }`}
                >
                  Filters
                </h3>
              </div>

              <div className="bg-gohan flex flex-col gap-2 rounded-moon-s-lg">
                <SimpleBar className="px-[30px] h-[calc(100dvh-270px)] overflow-y-auto">
                  <FilterSideBar
                    onDataUpdate={handleSidebarDataUpdate}
                    sidebarData={sidebarData}
                  />
                </SimpleBar>
              </div>
            </div>
          </div>
          
        <div className="2xl:col-span-10 col-span-9">
            <FilterEmployee
              setSearchQuery={setSearchQuery}
              employeeCount={tableData?.availableEmployees as number}
            />
            <SimpleBar className=" h-[calc(100dvh-281px)] overflow-y-auto 2xp:p-[0_30px] px-4">
              <EmployeesTable tableData={tableData?.employees as IEmployee[]} />
            </SimpleBar>
          </div>
        </div>
        {/* <FooterCalendar /> */}
      </div>
    </>
  );
};

export default Employees;
