import React, { useEffect, useState } from "react";
import RecentScheduleCard from "../../Widgets/cards/recent-schedule/RecentScheduleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { IShift } from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";

interface Avatar {
  image: string;
  border: string;
}

interface ShiftData {
  month: string;
  day: string;
  dayType: string;
  date: string;
  specialty: string;
  avatars: Avatar[];
  overflowCount: number;
}

interface Props {
  employeeData: IShift[];
}
const RecentSchedule: React.FC<Props> = ({ employeeData }) => {
  const data: ShiftData[] = [
    {
      month: "Nov",
      day: "6",
      dayType: "day",
      date: "6 November 2022",
      specialty: "Orthopedics",
      avatars: [
        {
          image: "./images/Avatar.png",
          border: "border-rose-500",
        },
        {
          image: "./images/Avatar.png",
          border: "border-indigo-600",
        },
        {
          image: "./images/Avatar.png",
          border: "border-blue-500",
        },
        {
          image: "./images/Avatar.png",
          border: "border-krillin",
        },
      ],
      overflowCount: 12,
    },
    {
      month: "Nov",
      day: "7",
      dayType: "Night",
      date: "7 November 2022",
      specialty: "ICU",
      avatars: [
        {
          image: "./images/Avatar.png",
          border: "border-rose-500",
        },
        {
          image: "./images/Avatar.png",
          border: "border-indigo-600",
        },
        {
          image: "./images/Avatar.png",
          border: "border-blue-500",
        },
        {
          image: "./images/Avatar.png",
          border: "border-krillin",
        },
      ],
      overflowCount: 12,
    },
    // Add more data objects as needed
  ];

  const [filteredData, setFilteredData] = useState<IShift[]>([] as IShift[]);

  useEffect(() => {
    if (employeeData) {
      const filteredData = employeeData?.filter(
        (data: any) => data?.isDayShift === true || data?.isNightShift === true
      );
      // console.log("Data", time, "TIME", filteredData);
      setFilteredData([...filteredData]);
    }
  }, [employeeData]);

  function formatDate(inputDateString: string, formatInto?: string) {
    const inputDate = new Date(inputDateString);
    if (formatInto == "month") {
      const options: any = { month: "short" };
      const formattedMonth = inputDate.toLocaleDateString("en-US", options);
      return formattedMonth?.toString();
    }
    if ((formatInto = "dayMonth")) {
      const options: any = { day: "numeric", month: "short", year: "numeric" };
      const formattedDate = inputDate.toLocaleDateString("en-US", options);
      return formattedDate?.toString();
    }
    return inputDateString;
  }

  return (
    <div>
      <div className="pt-5 px-5">
        <h3 className="font-bold 2xl:text-moon-16 text-moon-14  mb-[22px]">Recent Schedule</h3>
      </div>
      <div className="pl-5">
        <Swiper
          slidesPerView={1.5}
          spaceBetween={10}
          breakpoints={{
            768: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
          }}
          className="mySwiper"
        >
          {filteredData
            ? filteredData?.map((schedule: IShift, index: any) => (
                <SwiperSlide key={index}>
                  <RecentScheduleCard
                    month={formatDate(schedule?.date, "month")}
                    day={schedule?.dayNumber}
                    dayType={
                      schedule?.isDayShift === true
                        ? "day"
                        : schedule?.isNightShift === true
                        ? "night"
                        : ""
                    }
                    date={formatDate(schedule?.date, "dayMonth")}
                    specialty={""}
                    // avatars={schedule?.avatars}
                    overflowCount={0}
                  />
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </div>
    </div>
  );
};

export default RecentSchedule;
