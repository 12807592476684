import React, { FC, useEffect, useState } from "react";
import SectionMain from "./SectionMain";
import { Button, IconButton } from "@heathmont/moon-core-tw";
import { GenericDownload, GenericLogOut } from "@heathmont/moon-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getHospitalData } from "../../../services/super-admin-services/hospital.service";
import { GenericUpload } from "@heathmont/moon-icons-tw";
import { useLocation } from "react-router-dom";
import { VIEWS } from "../../../enums/branch-admin/views.enums";
interface Props {
  title: string;
  content: string;
  setViewOption: (option: string | null) => void;
  viewOption: string | null;
  handleImportClick?: () => void;
  handleDownloadFilePattern?: () => void;
  handleFileChange?: (event: any) => Promise<void>;
  fileInputRef?: any;
}
const PageTitle: React.FC<Props> = ({
  title,
  content,
  setViewOption,
  viewOption,
  handleImportClick,
  handleFileChange,
  handleDownloadFilePattern,
  fileInputRef,
}) => {
  const { User } = useSelector((state: RootState) => state.auth);
  const [hospital, setHospital] = useState<any>();
  const location = useLocation().pathname;

  const fetchHospitalData = async () => {
    try {
      const response: any = await getHospitalData(User?.hospital as string);
      if (response) {
        setHospital(response.res.hospitalDB);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (User?.hospital) fetchHospitalData();
  }, [User]);

  return (
    <div className="sm:px-7 px-4 bg-hit min-h-[118px]">
      <div className="flex items-end py-[23px]  -mx-[16px]">
        <div className="w-full px-[16px]">
          <div>
            {/* <h3 className="text-moon-14 font-semibold -tracking-[0.5px] text-white  mb-8px">
              {title}
            </h3> */}
            <SectionMain
              viewOption={viewOption}
              setViewOption={setViewOption}
            />

            <p className="text-white 2xl:text-moon-16 text-moon-14   mt-[8px]">{content}</p>
          </div>
        </div>
        <div className="w-4/5 px-[16px] text-right">
          <div className="flex items-center justify-end gap-2">
            {/* <div className="flex items-center rounded-xl bg-primarydark px-2 py-1">
              <img
                className="me-2 w-[37px] h-[37px] rounded-full"
                src={hospital ? hospital.logo : ""}
                alt=""
              />
              <p className="text-white text-moon-14">
                {hospital ? hospital.name : "Hospital name"}
              </p>
            </div> */}
            {viewOption &&
              viewOption !== VIEWS.SWAP_REQUEST &&
              // viewOption !== VIEWS.REQUEST_TIME_OFF &&
              viewOption !== VIEWS.SECTIONS &&
              location !== "/preferences" && (
                <div className="flex gap-2">
                  <Button
                    iconRight={<GenericDownload />}
                    className="ml-auto bg-white text-blackcst"
                    onClick={handleImportClick}
                  >
                    Import CSV
                  </Button>
                  <input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    iconRight={<GenericUpload />}
                    className="ml-auto bg-white text-blackcst"
                    onClick={handleDownloadFilePattern}
                  >
                    Export Template
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
