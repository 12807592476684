import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import WeekCalendar from "./Nurse-Manager/Schedule/WeekCalendar/Index";
import MonthCalenderView from "./Nurse-Manager/Schedule/MonthCalendar";
import AddSchedule from "./Nurse-Manager/add-schedule";
import EmptyView from "./Nurse-Manager/Schedule/MonthCalendar/EmptyView";
import Employees from "./Nurse-Manager/employees";
import Need from "./Nurse-Manager/need";
import ScheduleHeader from "../../Components/nurse-manager/layouts/header/ScheduleHeader";
import Requests from "./Nurse-Manager/requests";
import Profile from "../profile";
import PreferencesSetting from "./admin/preferences-setting";
import "../../";
import ScheduleRequest from "./nurse-director";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AdminViews from "../branch-admin/admin-views";
import Preferences from "../branch-admin/preferences";
import useIdleTime from "../../hooks/branch-admin/useIdleTime";
import SwapRequests from "./swap";

const Nurse_Manager_Routes = () => {
  const user = JSON.parse(sessionStorage.getItem("user")!);
  useIdleTime();
  return (
    <>
      <Router>
        <header>
          <ScheduleHeader />
        </header>

        <Routes>
          <Route path="/" element={<AddSchedule />} />
          <Route
            path="/scheduler"
            element={
              user?.designation === "nurse manager" ||
              user?.temporaryRole === "nurse manager" ? (
                <MonthCalenderView />
              ) : user?.designation === "nurse director" ? (
                <ScheduleRequest />
              ) : (
                ""
              )
            }
          />
          {user?.temporaryRole === "branch admin" && (
            <>
              <Route path="/home" element={<AdminViews />} />
              <Route path="/preferences" element={<Preferences />} />
            </>
          )}
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/" replace />} />

          {user?.designation === "nurse manager" ||
          user?.temporaryRole === "nurse manager" ? (
            <>
              <Route path="/empty-view" element={<EmptyView />} />
              <Route path="/employees" element={<Employees />} />
              <Route path="/need" element={<Need />} />
              <Route path="/request" element={<Requests />} />
              <Route path="/swap" element={<SwapRequests />} />
            </>
          ) : (
            ""
          )}
        </Routes>
      </Router>
    </>
  );
};

export default Nurse_Manager_Routes;
