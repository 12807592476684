import React from 'react';
import { Button, IconButton, Input, Label, Modal } from '@heathmont/moon-core-tw';
import { ControlsClose } from '@heathmont/moon-icons';
import DataSelect from './DataSelect ';

interface ModalAddRoleProps {
    isOpen: boolean;
    closeModal: () => void;
}

const options = [
    { name: '01' },
    { name: '02' },
    { name: '03' },
    { name: '04' },
    { name: '05' },
    { name: '06' },
];

const ModalAddRole: React.FC<ModalAddRoleProps> = ({ isOpen, closeModal }) => {
    return (

        <Modal open={isOpen} onClose={closeModal}>
            <Modal.Backdrop />
            <Modal.Panel className='max-w-[500px]'>
                <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
                    <h3 className="text-moon-20 font-semibold capitalize">Add User Role</h3>

                    <IconButton onClick={closeModal} variant="ghost" icon={<ControlsClose />} />
                </div>
                <div className="modal-content px-4 py-2  border-b border-beerus">
                    <div className="w-full mb-[24px]">
                        <Label htmlFor="#" className='2xl:text-moon-16 text-moon-14 text-[#000]'>Name of the User Role</Label>
                        <Input type="text" placeholder="Enter Role Name" />
                    </div>
                    <div className="w-full">
                        <DataSelect options={options} label="Order (Hierarchical)" />
                    </div>
                </div>
                <div className="modal-footer  px-4 py-2  flex items-center justify-end gap-[8px]">
                    <Button variant='secondary'>Discard</Button>
                    <Button variant='tertiary'>Add</Button>
                </div>
            </Modal.Panel>
        </Modal>
    );
};

export default ModalAddRole;
