import React, { useState } from "react";
import { Button } from "@heathmont/moon-core-tw";
import {
  ControlsChevronRight,
  GenericAlarmRound,
  GenericDelete,
} from "@heathmont/moon-icons-tw";
import ScheduleRecordCard from "../../../../../Components/nurse-manager/UI/ScheduleRecordCard";
import { scheduleMonthYear } from "../../add-schedule";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import {
  IScheduleHistory,
  ScheduleRecordCardData,
} from "../../../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import ConfirmationModal from "../../../../super-admin/settings/hospital/widgets/ConfirmationModal";
interface Props {
  scheduleRecordsData: IScheduleHistory;
  setSelectedRecord: (data: ScheduleRecordCardData) => void;
  setIsShowSchedule: (check: boolean) => void;
  setSelectedScheduleId: (scheduleId: string) => void;
  deleteSchedules:Function
}
const ViewRecord: React.FC<Props> = ({
  deleteSchedules,
  scheduleRecordsData,
  setSelectedRecord,
  setIsShowSchedule,
  setSelectedScheduleId,
}) => {
  const { date } = useSelector((state: RootState) => state.time);

  return (
    <>
      <div className="flex justify-between items-center lg:px-[30px] px-4 py-5 bg-gray-50 spacy-y-3">
        <div>
          <h3 className="text-moon-24 text-bulma ">View Records</h3>
          {/* <p className="flex items-center text-trunks">
            <GenericAlarmRound className="me-1 text-moon-24 text-bulma ps-0" />
            Clicking on
            <span className="flex items-center ms-1">
              <GenericDelete className="me-1 text-moon-20 text-trunks" /> will
              delete all the selected schedule permanently.
            </span>
          </p> */}
        </div>
        {/* <div className="flex items-center">
          <p className="me-4 text-moon-16 text-trunks">
            Return to current schedule
          </p>
          <Button
            variant="tertiary"
            className="text-hit text-moon-14 bg-[rgb(36,156,186,0.15)]"
          >
            {date.month + " " + date.year}
            <ControlsChevronRight className="text-moon-24 " />
          </Button>
        </div> */}
      </div>
      <div className=" lg:px-[30px] px-4 bg-gray-50 h-[calc(100dvh-316px)]">
        {scheduleRecordsData?.schedules?.length > 0 ? (
          <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 pb-2 gap-2">
            {scheduleRecordsData?.schedules.map(
              (schedule: ScheduleRecordCardData) => (
                <div key={schedule?._id}>
                  <ScheduleRecordCard
                    deleteSchedules={deleteSchedules}
                    schedule={schedule}
                    setSelectedRecord={setSelectedRecord}
                    setIsShowSchedule={setIsShowSchedule}
                    setSelectedScheduleId={setSelectedScheduleId}
                  />
                </div>
              )
            )}
          </div>
        ) : (
          <div className="sectionsvg flex items-center w-full justify-center text-center">
            <img
              src="images/no-schedule-to-approve.svg"
              alt="No Records available"
              className="inline-block"
            />
          </div>
        )}
      </div>
 

    </>
  );
};

export default ViewRecord;
