import { Switch } from "@heathmont/moon-core-tw";
import React, { useEffect, useState } from "react";
import { ISectionPreferences } from '../../../../types/branch-admin-interfaces/IWorkPreferences.interface';
interface Props {
  section: string;
  data: any;
  setPrefData: (data: ISectionPreferences) => void;
  validationErrors: any;
  onValidationErrors: (errors: any) => void;
}

const PreferencesForm: React.FC<Props> = ({
  section,
  data,
  setPrefData,
  validationErrors,
  onValidationErrors,
}) => {
  const [preferencesData, setPreferencesData] = useState<any>();
  const [dayNightDistribution,setDayNightDistribution] = useState<boolean>(false)
  const validateForm = () => {
    const errors: any = {};
    if (
      preferencesData?.sectionPreference?.preferences?.shiftDistributionDayShifts + 
      preferencesData?.sectionPreference?.preferences?.shiftDistributionNightShifts > 100
    ) {
      errors.shiftDistribution = 'Shift Distribution should be 0% - 100%.';
    }
    if (
      preferencesData?.sectionPreference?.preferences?.workingHours &&
      (preferencesData?.sectionPreference?.preferences?.workingHours < 1 ||
        preferencesData?.sectionPreference?.preferences?.workingHours > 12)
    ) {
      errors.workingHours = "Working hours should be 1 - 12 hours.";
    }
    if (
      preferencesData?.sectionPreference?.preferences?.weekendsAllowedToWork &&
      (preferencesData?.sectionPreference?.preferences?.weekendsAllowedToWork <
        0 ||
        preferencesData?.sectionPreference?.preferences?.weekendsAllowedToWork >
          10)
    ) {
      errors.weekendsAllowedToWork =
        "Weekends allowed to work should be 0 - 10.";
    }

    if (
      preferencesData?.sectionPreference?.preferences?.consecutiveShifts &&
      (preferencesData?.sectionPreference?.preferences?.consecutiveShifts < 2 ||
        preferencesData?.sectionPreference?.preferences?.consecutiveShifts > 30)
    ) {
      errors.consecutiveShifts = "Consecutive shifts should be 2 - 30.";
    }

    if (
      preferencesData?.sectionPreference?.preferences?.consecutiveDayShifts &&
      (preferencesData?.sectionPreference?.preferences?.consecutiveDayShifts <
        2 ||
        preferencesData?.sectionPreference?.preferences?.consecutiveDayShifts >
          30)
    ) {
      errors.consecutiveDayShifts =
        "Consecutive day shifts should be between 2 and 30.";
    }

    if (
      preferencesData?.sectionPreference?.preferences?.consecutiveNightShifts &&
      (preferencesData?.sectionPreference?.preferences?.consecutiveNightShifts <
        2 ||
        preferencesData?.sectionPreference?.preferences
          ?.consecutiveNightShifts > 30)
    ) {
      errors.consecutiveNightShifts =
        "Consecutive night shifts should be between 2 and 30";
    }

    if (
      preferencesData?.sectionPreference?.preferences?.overrideTimeOfRequests &&
      (preferencesData?.sectionPreference?.preferences?.overrideTimeOfRequests <
        0 ||
        preferencesData?.sectionPreference?.preferences
          ?.overrideTimeOfRequests > 100)
    ) {
      errors.overrideTimeOfRequests =
        "Override Time Of Requests should be 0% - 100%";
    }

    if (
      preferencesData?.sectionPreference?.preferences
        ?.patientForecastUtilization &&
      (preferencesData?.sectionPreference?.preferences
        ?.patientForecastUtilization < 0 ||
        preferencesData?.sectionPreference?.preferences
          ?.patientForecastUtilization > 100)
    ) {
      errors.patientForecastUtilization =
        "Forecast Patient Utilization should be 0% - 100%";
    }

    if (
      preferencesData?.sectionPreference?.preferences
        ?.shiftDistributionDayShifts &&
      (preferencesData?.sectionPreference?.preferences
        ?.shiftDistributionDayShifts < 0 ||
        preferencesData?.sectionPreference?.preferences
          ?.shiftDistributionDayShifts > 100)
    ) {
      errors.shiftDistributionDayShifts =
        "Shift Distribution should be 0% - 100%";
    }
    // console.log('preferencesData?.sectionPreference?.preferences?.workingHours < 1',)
    if (
      preferencesData?.sectionPreference?.preferences?.shiftDuration &&
      (preferencesData?.sectionPreference?.preferences?.shiftDuration < 1 ||
        preferencesData?.sectionPreference?.preferences?.shiftDuration > 12)
    ) {
      errors.shiftDuration = "Shift Duration should between 1 to 12";
    }

    if (
      preferencesData?.sectionPreference?.preferences?.maxROThatCanBeApplied &&
      (preferencesData?.sectionPreference?.preferences?.maxROThatCanBeApplied <
        0 ||
        preferencesData?.sectionPreference?.preferences?.maxROThatCanBeApplied >
          5)
    ) {
      errors.maxROThatCanBeApplied = "Max RO to apply should be 0 - 5";
    }
    
    if (
      preferencesData?.sectionPreference?.preferences
        ?.maxROThatCanBeAppliedWeekends &&
      (preferencesData?.sectionPreference?.preferences
        ?.maxROThatCanBeAppliedWeekends < 0 ||
        preferencesData?.sectionPreference?.preferences
          ?.maxROThatCanBeAppliedWeekends > 5)
    ) {
      errors.maxROThatCanBeAppliedWeekends =
      "Max RO to apply on weekends should be 0 - 1";
    }
  
    if (
      preferencesData?.sectionPreference?.preferences
        ?.overallShiftsGap &&
      (preferencesData?.sectionPreference?.preferences
        ?.overallShiftsGap < 1 ||
        preferencesData?.sectionPreference?.preferences
          ?.overallShiftsGap > 5)
    ) {
      errors.overallShiftsGap =
      "Overall shifts gap should be 1 - 5";
    }


    if (
      preferencesData?.sectionPreference?.preferences
        ?.shiftsAllocationPerMonth &&
      (preferencesData?.sectionPreference?.preferences
        ?.shiftsAllocationPerMonth < 0 ||
        preferencesData?.sectionPreference?.preferences
          ?.shiftsAllocationPerMonth > 31)
    ) {
      errors.shiftsAllocationPerMonth =
      "Shift Allocation Per Month should be between 0 - 31";
    }


    onValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    if ((data?.sectionPreference?.preferences?.shiftDistributionDayShifts === null 
      && data?.sectionPreference?.preferences?.shiftDistributionNightShifts === null) ||
      (!data?.sectionPreference?.preferences?.shiftDistributionDayShifts && 
      !data?.sectionPreference?.preferences?.shiftDistributionNightShifts ))
        { 
          setDayNightDistribution(false)
        }else{
          setDayNightDistribution(true)
        }
    setPreferencesData(data);
  }, [data]);

  const handleInputChange = (event:any, targetName?:string) => {
    if (!preferencesData?.sectionPreference?.preferences) return;

    const tempData = { ...preferencesData.sectionPreference.preferences };
    const name = targetName ? targetName : event?.target?.name;
    const value = targetName ? event : parseInt(event.target.value); 
    tempData[name] = value;

    // Calculate complementary shift values
    if (name === 'shiftDistributionDayShifts') {
      if(value>100){
        return
      }
      tempData.shiftDistributionNightShifts = 100 - value;
    } else if (name === 'shiftDistributionNightShifts') {
      if(value>100){
        return
      }
      tempData.shiftDistributionDayShifts = 100 - value;
    }

    // Check if the combined value is greater than 100
    const errors = { ...validationErrors };
    if (tempData.shiftDistributionDayShifts + tempData.shiftDistributionNightShifts > 100) {
      errors.shiftDistributionDayShifts = 'Combined shift distribution cannot exceed 100%';
      errors.shiftDistributionNightShifts = 'Combined shift distribution cannot exceed 100%';
    } else {
      errors.shiftDistributionDayShifts = '';
      errors.shiftDistributionNightShifts = '';
    }

    setPreferencesData((prevData:any) => ({
      ...prevData,
      sectionPreference: {
        ...prevData.sectionPreference,
        preferences: tempData,
      },
    }));

    // if (name === 'workingHours' && (event.target.value == '')) {
    //   console.log('here')
    //   onValidationErrors({
    //     ...validationErrors,
    //     [name]: 'Working hours should be greater than zero.',
    //   });
    //   return; // Stop further processing
    // }
    onValidationErrors({
      ...errors,
      [targetName ?? event.target.name]: undefined,
    });
  };

  useEffect(() => {
    let dataToPost: ISectionPreferences = {
      sectionId: section,
      preferences: { ...preferencesData?.sectionPreference?.preferences },
    };
  
    if (!dayNightDistribution) {
      delete dataToPost.preferences?.shiftDistributionNightShifts;
      delete dataToPost.preferences?.shiftDistributionDayShifts;
    }
  
    setPrefData(dataToPost);
    validateForm();
  }, [preferencesData]);

  const handleDayNightShift =(e:boolean)=>{
  
    const pref = {...preferencesData?.sectionPreference.preferences}
    if(e){

      setDayNightDistribution(true)
      setPreferencesData((prevData:any) => ({
        ...prevData,
        sectionPreference: {
          preferences :{
            ...pref,
            shiftDistributionDayShifts:data.sectionPreference.preferences.shiftDistributionDayShifts,
            shiftDistributionNightShifts:data.sectionPreference.preferences.shiftDistributionNightShifts
          }      
        }
      })); 
    }else{
      setDayNightDistribution(false)
      setPreferencesData((prevData:any) => ({
        ...prevData,
        sectionPreference:{
          preferences:{
            ...pref,
            shiftDistributionDayShifts:0,
            shiftDistributionNightShifts:0
          }
        }
      }));

    }
  }

  return (
    <>
      <div>
        <div className="grid grid-cols-3 gap-4  items-center ">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Working hours</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">min 1 hr - max 12 hrs</p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                preferencesData?.sectionPreference?.preferences
                  ?.workingHours === null
                  ? ""
                  : preferencesData?.sectionPreference?.preferences
                      ?.workingHours
              }
              name="workingHours"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.workingHours ? "border-red-500" : ""
              }`}
            />
            {validationErrors.workingHours && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.workingHours}
              </div>
            )}
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4  items-center">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Shift Allocation Per Month</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">min 1 - max 31</p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                preferencesData?.sectionPreference?.preferences
                  ?.shiftsAllocationPerMonth === null
                  ? ""
                  : preferencesData?.sectionPreference?.preferences
                      ?.shiftsAllocationPerMonth
              }
              name="shiftsAllocationPerMonth"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.shiftsAllocationPerMonth ? "border-red-500" : ""
              }`}
            />
            {validationErrors.shiftsAllocationPerMonth && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.shiftsAllocationPerMonth}
              </div>
            )}
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4  items-center">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Overall Shifts Gap</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">min 1 - max 5</p>
          </div>
          <div className="">
            <input
              type="number"
              placeholder="0"
              value={
                preferencesData?.sectionPreference?.preferences
                  ?.overallShiftsGap === null
                  ? ""
                  : preferencesData?.sectionPreference?.preferences
                      ?.overallShiftsGap
              }
              name="overallShiftsGap"
              onChange={handleInputChange}
              className={`prefrenceInput ${
                validationErrors.overallShiftsGap ? "border-red-500" : ""
              }`}
            />
            {validationErrors.overallShiftsGap && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.overallShiftsGap}
              </div>
            )}
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4  items-center">
          <div className=" ">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Weekends allowed to work
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Set preference for your employees to allow scheduling on weekends
              min 0 - max 10
            </p>
          </div>
          <div className=" col-span-2">
            <input
              type="number"
              placeholder="0"
              value={
                preferencesData?.sectionPreference?.preferences
                  ?.weekendsAllowedToWork === null
                  ? ""
                  : preferencesData?.sectionPreference?.preferences
                      ?.weekendsAllowedToWork
              }
              name="weekendsAllowedToWork"
              onChange={handleInputChange}
              className="prefrenceInput"
            />
            {validationErrors.weekendsAllowedToWork && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.weekendsAllowedToWork}
              </div>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 items-center gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Request Time Off</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Set preference to allow RO and only 1 RO can be on weekend min 0 -
              max 5
            </p>
          </div>
          <div className="col-span-2/3 ">
            <div className="flex flex-wrap  gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.maxROThatCanBeApplied === null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.maxROThatCanBeApplied
                  }
                  name="maxROThatCanBeApplied"
                  onChange={handleInputChange}
                  className="prefrenceInput"
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Week Days
                </label>
              </div>
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  className="prefrenceInput"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.maxROThatCanBeAppliedWeekends == null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.maxROThatCanBeAppliedWeekends
                  }
                  name="maxROThatCanBeAppliedWeekends"
                  onChange={handleInputChange}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Weekends
                </label>
              </div>
            </div>

            {validationErrors.maxROThatCanBeApplied ? (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.maxROThatCanBeApplied}
              </div>
            ) : validationErrors?.maxROThatCanBeAppliedWeekends ? (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.maxROThatCanBeAppliedWeekends}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />

        <div className="grid grid-cols-3 items-center gap-4">
          <div className=" ">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Override Time-off requests
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Override Time Of Requests should be 0% - 100%
            </p>
          </div>
          <div className=" ">
            <div className="inline-flex items-center gap-[10px]">
              <input
                type="number"
                placeholder="0"
                value={
                  preferencesData?.sectionPreference?.preferences
                    ?.overrideTimeOfRequests === null
                    ? ""
                    : preferencesData?.sectionPreference?.preferences
                        ?.overrideTimeOfRequests
                }
                name="overrideTimeOfRequests"
                onChange={handleInputChange}
                className="prefrenceInput"
              />
              <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">%</label>
            </div>
            <div>
              {validationErrors.overrideTimeOfRequests && (
                <div className="text-red-500 text-moon-14 mt-2">
                  {validationErrors.overrideTimeOfRequests}
                </div>
              )}
            </div>
          </div>
        </div>

        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Consecutive Shifts</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Consecutive shifts can be min 2 - max 30
            </p>
          </div>
          <div className="col-span-2">
            <div className="flex flex-wrap  gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.consecutiveDayShifts === null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.consecutiveDayShifts
                  }
                  name="consecutiveDayShifts"
                  onChange={handleInputChange}
                  className="prefrenceInput"
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Day shifts
                </label>
              </div>
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  className="prefrenceInput"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.consecutiveNightShifts == null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.consecutiveNightShifts
                  }
                  name="consecutiveNightShifts"
                  onChange={handleInputChange}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Night shifts
                </label>
              </div>
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  className="prefrenceInput"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.consecutiveShifts == null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.consecutiveShifts
                  }
                  name="consecutiveShifts"
                  onChange={handleInputChange}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  Overall shifts
                </label>
              </div>
            </div>
            <div className="items-center pt-2">
              {validationErrors.consecutiveDayShifts ? (
                <div className="text-red-500 text-moon-14 mt-2">
                  {validationErrors.consecutiveDayShifts}
                </div>
              ) : validationErrors.consecutiveNightShifts ? (
                <div className="text-red-500 text-moon-14 mt-2">
                  {validationErrors.consecutiveNightShifts}
                </div>
              ) : validationErrors.consecutiveShifts ? (
                <div className="text-red-500 text-moon-14 mt-2">
                  {validationErrors.consecutiveShifts}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3 gap-4 items-center ">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Shift duration</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Shift duration can be min 1 hr - max 12 hrs
            </p>
          </div>
          <div className=" ">
            <div className="grid gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  className="prefrenceInput"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.shiftDuration === null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.shiftDuration
                  }
                  name="shiftDuration"
                  onChange={handleInputChange}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">
                  hours
                </label>
              </div>
            </div>
            {validationErrors.shiftDuration && (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.shiftDuration}
              </div>
            )}
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />

        <div className="grid grid-cols-3  items-center gap-4">
          <div className="">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Set to allocate consecutive shifts
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Set to allow an employee if they can take consecutive shifts
              within a day span
            </p>
          </div>
          <div className="">
            <div className="grid gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <Switch
                  // setToAllocateConsecutiveShifts
                  checked={
                    preferencesData?.sectionPreference?.preferences
                      ?.setToAllocateConsecutiveShifts as boolean
                  }
                  onChange={(event: any) =>
                    handleInputChange(event, "setToAllocateConsecutiveShifts")
                  }
                  size="xs"
                  name="notification"
                  id="notification"
                  value="on"
                  className="bg-beerus moon-checked:bg-cyan-500"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />

        <div className="grid grid-cols-3  items-center gap-4">
          <div className=" ">
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
              Forecast Patient Utilization
            </h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Set for the percentage of forecast patient utilization from 0% -
              100%
            </p>
          </div>
          <div className="col-span-2  ">
            <div className="inline-flex items-center gap-[10px]">
              <input
                type="number"
                placeholder="0"
                value={
                  preferencesData?.sectionPreference?.preferences
                    ?.patientForecastUtilization === null
                    ? ""
                    : preferencesData?.sectionPreference?.preferences
                        ?.patientForecastUtilization
                }
                name="patientForecastUtilization"
                onChange={handleInputChange}
                className="prefrenceInput"
              />
              <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">%</label>
            </div>
            <div>
              {validationErrors.patientForecastUtilization && (
                <div className="text-red-500 text-moon-14 mt-2">
                  {validationErrors.patientForecastUtilization}
                </div>
              )}
            </div>
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />
        <div className="grid grid-cols-3  items-center gap-4">
          {/* <input type="checkbox" checked={dayNightDistribution} className="self-start mt-1 color-primary" onClick={handleDayNightShift} /> */}
          <div className={` ${dayNightDistribution ? '':'opacity-50'}`} >
            <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">Shifts Distribution</h4>
            <p className="text-gray-600 2xl:text-moon-14 text-moon-12 ">
              Set here how the shifts would be divided between day and night shifts
            </p>

          </div>
          <div className="col-span-2">
            <div className="flex flex-wrap  gap-[10px]">
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  min={0}
                  max={100}
                  disabled={!dayNightDistribution}
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.shiftDistributionDayShifts === null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.shiftDistributionDayShifts
                  }
                  name="shiftDistributionDayShifts"
                  onChange={handleInputChange}
                  className="prefrenceInput"
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">% Day Shifts</label>
              </div>
              <div className="inline-flex items-center gap-[10px]">
                <input
                  type="number"
                  placeholder="0"
                  min={0}
                  max={100}
                  disabled={!dayNightDistribution}
                  className="prefrenceInput"
                  value={
                    preferencesData?.sectionPreference?.preferences
                      ?.shiftDistributionNightShifts == null
                      ? ""
                      : preferencesData?.sectionPreference?.preferences
                          ?.shiftDistributionNightShifts
                  }
                  name="shiftDistributionNightShifts"
                  onChange={handleInputChange}
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">% Night Shifts </label>
                <Switch
                  checked={dayNightDistribution}
                  onChange={handleDayNightShift}
                  size="xs"
                  name="notification"
                  id="notification"
                  value="on"
                  className="bg-beerus moon-checked:bg-cyan-500 w-[40px]"
                />
                <label className="flex-1 text-gray-600 2xl:text-moon-14 text-moon-12 ">Enable/Disable</label>
              </div>
           
            </div>
            <div className="items-center pt-2">
            {validationErrors.shiftDistributionDayShifts ? (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.shiftDistributionDayShifts}
              </div>
            ) : validationErrors.shiftDistributionNightShifts ? (
              <div className="text-red-500 text-moon-14 mt-2">
                {validationErrors.shiftDistributionNightShifts}
              </div>
            ) : (
              <></>
            )}
            </div>
          </div>
        </div>
        <hr className="border-b border-[#EBEBEB] my-[20px]" />

      </div>
    </>
  );
};

export default PreferencesForm;
