import moment from "moment";
import React from "react";
interface Props {
  dayNames: string[];
}
const WeekDays: React.FC<Props> = ({ dayNames }) => {
  const currentDay = moment().format("dddd");
  return (
    <div className="flex flex-wrap w-full">
      {dayNames?.map((day, index) => (
        <div
          key={index}
          className={`${
            day === currentDay ? "bg-primary text-white" : ""
          } basis-1/7 h-[64px] border border-beerus border-s-0 px-3 py-2 inline-flex items-center`}
        >
          <div className={`text-moon-14 font-semibold transition-colors`}>
            {day}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WeekDays;
