import React from "react";
import { Icon } from "@iconify/react";
const FooterCalendar = () => {
  return (
    <div className="flex items-center 2xl:px-[30px] px-5 py-[10px] ">
      <div className="w-[300px]">
        <ul className="flex flex-wrap gap-3">
          <li className="flex gap-1 items-center">
            <Icon className="text-dodoria" icon="ic:round-circle" />{" "}
            <span className="flex-1 2xl:text-moon-12 text-moon-10 transition-colors">
              Senior Nurse
            </span>
          </li>
          {/* <li className="flex gap-1 items-center">
            <Icon className="text-indigo-600" icon="ic:round-circle" />{" "}
            <span className="flex-1 text-moon-12 transition-colors">
              Leader
            </span>
          </li> */}
          <li className="flex gap-1  items-center">
            <Icon className="text-whis" icon="ic:round-circle" />{" "}
            <span className="flex-1 2xl:text-moon-12 text-moon-10 transition-colors">Nurse</span>
          </li>
          <li className="flex gap-1 items-center">
            <Icon className="text-krillin" icon="ic:round-circle" />{" "}
            <span className="flex-1 2xl:text-moon-12 text-moon-10 transition-colors">HCA</span>
          </li>
        </ul>
      </div>
      <div className="w-full">
        <ul className="flex flex-wrap gap-3 justify-end">
          <li className="flex gap-1 items-center">
            <div className="bg-amber-100 text-amber-700 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              AL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Annual Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-cyan-100 text-cyan-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              RO
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Request Off
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-blue-100 text-blue-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              CL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Compensation Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-red-100 text-red-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              EL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Emergency Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-pink-100 text-pink-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              ML
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Maternity Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-green-100 text-green-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              PL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Paternity Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-yellow-100 text-yellow-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              SL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Study Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-purple-100 text-purple-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              UL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Unpaid Leave
            </span>
          </li>
          <li className="flex gap-1 items-center">
            <div className="bg-gray-100 text-gray-600 overflow-hidden uppercase font-medium flex items-center 2xl:text-moon-12 text-moon-10 justify-center bg-cover h-6 w-6 text-moon-10-caption rounded-moon-i-xs">
              SL
            </div>
            <span className="flex-1 pl-2 2xl:text-moon-12 text-moon-10 transition-colors">
              Sick Leave
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterCalendar;
