import { Button } from "@heathmont/moon-core-tw";
import { ControlsPlus } from "@heathmont/moon-icons";
import React from "react";

const HeaderSetting = ({ title, leadText, buttonText, handleModal }: any) => {
  return (
    <div className="py-5 2xl:px-8 px-4">
      <div className="flex flex-wrap items-center -mx-4">
        <div className="w-1/2 px-4">
          <h3 className="text-moon-20 text-blackcst mb-1 font-semibold">
            {title}
          </h3>
          <p className="text-moon-14 text-gray-600">{leadText}</p>
        </div>
        {handleModal ? (
          <div className="w-1/2 px-4">
            <Button
              variant="tertiary"
              onClick={handleModal}
              className="ml-auto"
              iconLeft={<ControlsPlus />}
            >
              {buttonText}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default HeaderSetting;
