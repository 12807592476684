import React, { FC } from "react";
interface Props {
  title: string;
  content: string;
}
const PageTitle: React.FC<Props> = ({ title, content }) => {
  return (
    <div className="sm:px-7 px-4 bg-hit min-h-[118px]">
      <div className="flex items-end py-[23px]  -mx-[16px]">
        <div className="w-2/4 px-[16px]">
          <div>
            <h3 className="2xl:text-moon-32 text-moon-24 font-semibold -tracking-[0.5px] text-white ">
              {title}
            </h3>
            <p className="text-white 2xl:text-moon-16 text-moon-14 2xl:mt-[8px] mt-2 opacity-50 ">
              {content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
