import { Button, Input, Label, Radio } from "@heathmont/moon-core-tw";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  canCompleteProfile,
  createBranchAdmin,
} from "../../services/branch-admin-services/createProfile.service";
import {
  setSnackBar,
  setToastMessage,
} from "../../features/ToastSlice/toastSlice";
import { store } from "../../store/store";
interface ProfileFromData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  contactNo: string;
  gender: string;
  address: string;
  uuid: string;
}
export interface ProfileData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  contactNo: string;
  gender: string;
  address: string;
  uuid: string;
}

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  contactNo: "",
  gender: "",
  address: "",
  uuid: "",
};
const passwordRegex = /.{8,32}$/;

const CreateProfile = () => {
  const [profileData, setProfileData] = useState<ProfileFromData>(initialState);
  const [canCreateProfile, setCanCreateProfile] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState("");
  const email = new URLSearchParams(window.location.search).get("email");
  const secret = new URLSearchParams(window.location.search).get("secret");
  const navigate = useNavigate();

  const handleCanCreateProfile = async (email: string, secret: string) => {
    const res: any = await canCompleteProfile(email, secret);
    setCanCreateProfile(res?.res?.response as boolean);
  };
  const handleCreateBranchAdmin = async (
    event: React.FormEvent<HTMLFormElement>,
    secret: string
  ) => {
    event.preventDefault();
    const reqBody = {
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      email: profileData?.email,
      password: profileData?.password,
      contactNo: profileData?.contactNo,
      gender: profileData?.gender,
      address: profileData?.address,
      uuid: profileData?.uuid,
    };
    const res = await createBranchAdmin(secret, reqBody);
    if (res) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(
        setToastMessage("Your profile has been created successfuly!")
      );
      navigate("/");
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("An error occured. Try again later."));
    }
    console.log("res of create branch: ", res?.res);
  };

  const handleBlur = (value: string) => {
    const regex = passwordRegex;

    if (!value.match(regex)) {
      setPasswordError(`Password should be at least 8 characters long.`);
    }
  };
  useEffect(() => {
    if (!secret || !email) return;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      email: email,
      gender: "Male",
      uuid: secret,
    }));
    handleCanCreateProfile(email, secret);
  }, [secret, email]);

  return (
    <div className="h-screen flex flex-col justify-between bg-form-pattern bg-no-repeat bg-center p-4 bg-cover ">
      <div className="flex text-center flex-1  flex-col justify-center mb-[82px]">
        <div className="mx-auto  max-w-[639px] w-full pt-12">
          <img
            className="mx-auto mb-20"
            src={"images/login-weblogo.png"}
            alt="logo"
          />
          <h3 className="text-3xll mb-5">Create Profile</h3>
          <p className="text-base text-[#8697A2] mb-10 max-w-[546px] mx-auto">
            Fill the following fields to create your profile
          </p>

          <form
            className="mx-auto py-9 px-10 bg-white rounded-xl shadow-3xl w-full"
            onSubmit={(event) => {
              secret && handleCreateBranchAdmin(event, secret);
            }}
          >
            {canCreateProfile ? (
              <div className="flex flex-wrap -mx-1">
                <div className="w-1/2 px-1 mb-3">
                  <Label htmlFor="fName" className="text-start">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        firstName: e?.target?.value,
                      }));
                    }}
                    required
                  />
                </div>
                <div className="w-1/2 px-1 mb-3">
                  <Label htmlFor="lName" className="text-start">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        lastName: e?.target?.value,
                      }));
                    }}
                    required
                  />
                </div>
                <div className="w-1/2 px-1 mb-3">
                  <Label htmlFor="contact" className="text-start">
                    Contact Number
                  </Label>

                  <Input
                    type="tel"
                    placeholder="e.g. +971 123 4567"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        contactNo: e?.target?.value,
                      }));
                    }}
                    required
                  />
                </div>
                <div className="w-1/2 px-1 mb-3">
                  <Label htmlFor="gender" className="text-start">
                    Gender
                  </Label>
                  <Radio
                    defaultValue="Male"
                    className="flex gap-4"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        gender: e as string,
                      }));
                    }}
                  >
                    <Radio.Option value="Male">
                      <Radio.Indicator className="moon-checked:border-primary after:bg-primary" />
                      Male
                    </Radio.Option>
                    <Radio.Option value="Female">
                      <Radio.Indicator className="moon-checked:border-primary after:bg-primary" />
                      Female
                    </Radio.Option>
                  </Radio>
                </div>
                <div className="w-full px-1 mb-3">
                  <Label htmlFor="address" className="text-start">
                    Address
                  </Label>
                  <Input
                    type="text"
                    placeholder="Address"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        address: e?.target?.value,
                      }));
                    }}
                    required
                  />
                </div>
                <div className="w-full px-1 mb-3">
                  <Label htmlFor="email" className="text-start">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    placeholder="Email Address"
                    value={profileData?.email}
                    disabled
                  />
                </div>
                <div className="w-full px-1 mb-3">
                  <Label className="text-start">Password</Label>
                  <Input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        password: e?.target?.value,
                      }));
                    }}
                    onBlur={() => handleBlur(profileData?.password)}
                    onFocus={() => {
                      setPasswordError("");
                    }}
                    required
                  />
                  {passwordError && (
                    <p className="text-dodoria text-left text-xs">
                      {passwordError}
                    </p>
                  )}
                </div>
                <div className="w-full px-1 mb-3">
                  <Label className="text-start">Re-enter Password</Label>
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) => {
                      setProfileData((prevProfileData) => ({
                        ...prevProfileData,
                        confirmPassword: e?.target?.value,
                      }));
                    }}
                    required
                  />
                  {profileData.confirmPassword.length !== 0 &&
                    profileData.password !== profileData.confirmPassword && (
                      <p className=" text-dodoria text-left text-xs">
                        Password doesn't match
                      </p>
                    )}
                </div>

                <div className="w-full px-1">
                  <Button
                    className="min-w-[150px] h-10 ml-auto shadow-btnshadow "
                    type="submit"
                    variant="tertiary"
                    disabled={
                      !canCreateProfile ||
                      Object.values(profileData).some(
                        (value) => value === ""
                      ) ||
                      passwordError !== "" ||
                      profileData.password !== profileData.confirmPassword
                    }
                  >
                    Create
                  </Button>
                </div>
              </div>
            ) : (
              <div>Profile already exists with this email.</div>
            )}
          </form>
        </div>
      </div>
      <hr />
      <div className="footer px-24 py-6">
        <div className="flex justify-between items-center">
          <p className="text-base text-[#8697A2]">
            Copyright ©
            <span className="text-[#249CBA] mx-1">
              Healthcare Management Portal |
            </span>
            All Rights Reserved
          </p>

          <img src={"images/healthlogo.svg"} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
