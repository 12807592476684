import React, { useEffect, useState } from "react";
import FilterShifts from "../UI/FilterShifts";
import FilterWeekMonth from "../UI/FilterWeekMonth";
import MonthlySchedule from "../Calendar/monthly/MonthlySchedule";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button } from "@heathmont/moon-core-tw";
import { ISection } from "../../../types/branch-admin-interfaces/ISection.interface";
import { ISectionInfo } from "../../../types/nurse-manager-interfaces/ISectionInfo.interface";
import { ScheduleRecordCardData } from "../../../types/nurse-manager-interfaces/IScheduleHistory.interface";

// const months: any = Array.from({ length: 12 }, (_, index) => ({
//   name: `${moment().month(index).format("MMMM")}`,
//   monthName: moment().month(index).format("MMMM"),
//   label: index + 1,
// }));

// const years: any = Array.from({ length: 15 }, (_, index) => ({
//   name: currentYear - index,
//   year: currentYear - index,
//   label: "year",
// }));

interface Props {
  sectionInfo?: ISectionInfo;
  setControlView?: (data: boolean) => void;
  controlView?: boolean;
  responseOfScheduleRequest?: (data: any) => void;
  selectedSection?: ISection;
  selectedRecord?: ScheduleRecordCardData;
  selectedScheduleId?: string;
  setSelectedScheduleId?: (id: string) => void;
  setIsShowSchedule?: (check: boolean) => void;
}
const MonthlyScheduleCalendar: React.FC<Props> = ({
  sectionInfo,
  setControlView,
  controlView,
  responseOfScheduleRequest,
  selectedSection,
  selectedRecord,
  selectedScheduleId,
  setSelectedScheduleId,
  setIsShowSchedule,
}) => {
  const { date } = useSelector((state: RootState) => state.time);

  // console.log("MONTH", );
  const User = useSelector((state: RootState) => state.auth.User);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [isScheduleAvailable, setIsScheduleAvailable] = useState(false);
  const [month, setMonth] = useState(
    {
      name: "",
      monthName: "",
      label: "",
    }
    //   {
    //   name: moment()?.format("MMMM"),
    //   monthName: moment()?.format("MMMM"),
    //   label: moment()?.format("MM"),
    // }
  );

  const isNurseDirector =
    User?.designation === "nurse director" && isScheduleAvailable;
  // const months = Array.from({ length: 12 }, (_, index) => ({
  //   name: `${moment().month(index).format("MMMM")}`,
  //   monthName: moment().month(index).format("MMMM"),
  //   label: index + 1,
  // }));

  const [year, setYear] = useState(
    {
      name: "",
      year: "",
      label: "",
    }
    //   {
    //   name: moment()?.format("YYYY"),
    //   year: moment()?.format("YYYY"),
    //   label: "year",
    // }
  );

  useEffect(() => {
    if (!selectedRecord) return;
    setMonth({
      name: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MMMM"),
      monthName: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MMMM"),
      label: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MM"),
    });
    setYear({
      name: moment()
        ?.year(selectedRecord?.year as number)
        ?.format("YYYY"),
      year: moment()
        ?.year(selectedRecord?.year as number)
        ?.format("YYYY"),
      label: "year",
    });
  }, [selectedRecord]);

  useEffect(() => {
    if (!sectionInfo) return;
    setMonth({
      name: `${moment()
        ?.month(sectionInfo?.month - 1)
        ?.format("MMMM")}`,
      monthName: `${moment()
        ?.month(sectionInfo?.month - 1)
        ?.format("MMMM")}`,
      label: moment()
        ?.month(sectionInfo?.month - 1)
        ?.format("MM"),
    });
    setYear({
      name: `${moment()
        ?.year(sectionInfo?.year as number)
        ?.format("YYYY")}`,
      year: `${moment()
        ?.year(sectionInfo?.year as number)
        ?.format("YYYY")}`,
      label: "year",
    });
  }, [sectionInfo]);

  const navigate = useNavigate();
  return (
    <>
      {(User?.designation === "nurse manager" ||
        User?.temporaryRole === "nurse manager" ||
        isNurseDirector) && (
        <div className="py-[10px] sm:px-7 px-4 bg-gohan shadow-moon-lg relative">
          <div className="flex gap-[10px] items-center w-full">
            <div className={`flex-1 flex gap-[10px] `}>
              {isScheduleAvailable ? (
                // <div className="flex items-center gap-[10px]">
                <>
                  <div>
                    <FilterShifts />
                  </div>
                  <div>
                    <FilterWeekMonth />
                  </div>
                </>
              ) : (
                // </div>
                ""
              )}
            </div>
            {sectionInfo ? (
              <div className="flex items-center gap-5">
                {sectionInfo?.status !== "accepted" &&
                sectionInfo?.status !== "declined" ? (
                  <div className="flex items-center gap-[10px]">
                    <Button
                      variant="secondary"
                      // size="sm"
                      // className="min-h-[42px]"
                      onClick={() => {
                        responseOfScheduleRequest &&
                          responseOfScheduleRequest({
                            sectionId: sectionInfo?._id,
                            status: "declined",
                          });
                        setFetchAgain(true);
                        setControlView && setControlView(!controlView);
                      }}
                    >
                      Decline
                    </Button>
                    <Button
                      variant="tertiary"
                      // size="sm"
                      // className="min-h-[42px]"
                      onClick={() => {
                        responseOfScheduleRequest &&
                          responseOfScheduleRequest({
                            sectionId: sectionInfo?._id,
                            status: "accepted",
                          });
                        setControlView && setControlView(!controlView);
                        setFetchAgain(true);
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <Button
                  variant="secondary"
                  // size="sm"
                  // className="min-h-[42px]"
                  onClick={() => setControlView && setControlView(!controlView)}
                >
                  Back to Scheduler
                </Button>
              </div>
            ) : (
              ""
            )}
            {User?.designation !== "nurse director" ? (
              <>
                {/* <div className="max-w-[173px] w-full">
                    <DropDown
                      field={"monthName"}
                      value={month}
                      options={months}
                      setOptions={setMonth}
                    />
                  </div>
                  <div className="max-w-[173px] w-full">
                    <DropDown
                      field={"name"}
                      value={year}
                      options={years}
                      setOptions={setYear}
                    />
                  </div> */}
                <div className="max-w-[138px] w-full">
                  <Button
                    // className="min-h-[42px]"
                    variant="secondary"
                    onClick={() => {
                      setIsShowSchedule && setIsShowSchedule(false);
                      navigate("/");
                    }}
                  >
                    Back to Records
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <div className="bg-white">
        <MonthlySchedule
          sectionInfo={sectionInfo as ISectionInfo}
          selectedScheduleId={selectedRecord?._id}
          setSelectedScheduleId={setSelectedScheduleId}
          fetchAgain={fetchAgain}
          isScheduleAvailable={isScheduleAvailable}
          setIsScheduleAvailable={setIsScheduleAvailable}
          selectedSection={selectedSection}
          month={month}
          year={year}
          selectedRecord={selectedRecord as ScheduleRecordCardData}
        />
      </div>
    </>
  );
};

export default MonthlyScheduleCalendar;
