import React from "react";
import { flexRender } from "@tanstack/react-table";
import { ITableDataResponse } from "../../../types/ITableData.interface";
import { generatePageNumbers } from "../../../utils/generatePageNumbers";
import { IconButton } from "@heathmont/moon-core-tw";
import {
  ArrowsSorting,
  ControlsChevronLeftSmall,
  ControlsChevronRightSmall,
} from "@heathmont/moon-icons";
import { ArrowsUp } from "@heathmont/moon-icons-tw";

interface Props {
  header?:boolean;
  table: any;
  tableData: ITableDataResponse;
  handlePageChange: (data: number) => void;
}

const Table: React.FC<Props> = ({ table, tableData, handlePageChange , header}) => {
  const intialRecord =
    tableData?.currentPage * Number(tableData?.limit) -
    Number(tableData?.limit) +
    (tableData?.totalRecords === 0 ? 0 : 1);
  const finalRecord =
    tableData?.currentPage * Number(tableData?.limit) -
    Number(tableData?.limit) +
    Number(tableData?.records?.length);
  if (!tableData || !tableData?.records) {
    return (
      <div className="sectionsvg flex items-center justify-center text-center">
        {/* You can render a loading indicator or an error message here */}
        {/* Loading or No Data Available */}
        <img
          src={"images/no-schedule-to-approve.svg"}
          alt="No schedule to approve"
        />
      </div>
    );
  }

  return (
    <div>
      <div className={`branchtable overflow-y-auto relative z-[1] ${header? "h-[calc(100dvh-393px)_!important]":''}`}>
        <table className="w-full">
          <thead>
            {table?.getHeaderGroups()?.map((headerGroup: any) => (
              <tr key={headerGroup?.id}>
                {headerGroup?.headers?.map((header: any) => (
                  <th
                    key={header?.id}
                    colSpan={header.colSpan}
                    className="text-left border 2xl:py-4 py-3 2xl:px-5 px-3 text-moon-12 capitalize sticky top-0 bg-grey300 border-grey300 z-50"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? " select-none flex justify-between"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() == "desc" ? (
                          <IconButton
                            size="xs"
                            variant="ghost"
                            className={` p-0 h-[24px] rotate-180 cursor-pointer`}
                            icon={<ArrowsUp />}
                          />
                        ) : header.column.getIsSorted() == "asc" ? (
                          <IconButton
                            size="xs"
                            variant="ghost"
                            className={` p-0 h-[24px] cursor-pointer`}
                            icon={<ArrowsUp />}
                          />
                        ) : header.column.columnDef.enableSorting !== false ? (
                          // <>⬍{console.log("A", header.column)}</>
                          <IconButton
                            size="xs"
                            variant="ghost"
                            className={` p-0 h-[24px] cursor-pointer`}
                            icon={<ArrowsSorting />}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table?.getRowModel()?.rows?.map((row: any) => (
              <tr key={row?.id}>
                {row?.getVisibleCells()?.map((cell: any) => (
                  <td
                    key={cell?.id}
                    className="text-left border py-2 2xl:px-5 px-3 2xl:text-moon-14 text-moon-12"
                  >
                    {flexRender(
                      cell?.column?.columnDef?.cell,
                      cell?.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table?.getFooterGroups()?.map((footerGroup: any) => (
              <tr key={footerGroup?.id}>
                {footerGroup?.headers?.map((header: any) => (
                  <th key={header?.id}>
                    {header?.isPlaceholder
                      ? null
                      : flexRender(
                          header?.column?.columnDef?.footer,
                          header?.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {/*  */}
      </div>

      <div className="flex items-center p-4 gap-[65px] bg-slate-200 fixed bottom-0 left-0 right-0">
        <div className="w-3/5">
          <div className="flex items-center  text-moon-12">
            <div>Showing </div> &nbsp;
            {intialRecord} - {finalRecord}&nbsp;
            <span className="text-[#999CA0]">
              of {Number(tableData?.totalRecords)}
            </span>
          </div>
        </div>
        <div className="w-2/5">
          <div className="flex items-center justify-between">
            <IconButton
              icon={<ControlsChevronLeftSmall className="rtl:rotate-180" />}
              variant="secondary"
              size="sm"
              onClick={() => {
                handlePageChange(tableData?.currentPage - 1);
              }}
              disabled={!tableData?.hasPreviousPage}
              aria-label="Previous"
            />
            <div className="flex items-center gap-2">
              {generatePageNumbers(tableData)?.map((pageNumber, index) => (
                <React.Fragment key={index}>
                  {pageNumber === "..." ? (
                    <span className="mx-1">{pageNumber}</span>
                  ) : (
                    <button
                      className={`flex items-center justify-center cursor-pointer w-8 h-8 rounded-moon-s-sm focus:outline-none transition-colors font-medium ${
                        pageNumber === tableData?.currentPage
                          ? "bg-primary text-white"
                          : ""
                      }`}
                      onClick={() =>
                        typeof pageNumber === "number" &&
                        handlePageChange(pageNumber)
                      }
                    >
                      {pageNumber}
                    </button>
                  )}
                </React.Fragment>
              ))}
            </div>

            <IconButton
              icon={<ControlsChevronRightSmall className="rtl:rotate-180" />}
              variant="secondary"
              size="sm"
              onClick={() => {
                handlePageChange(tableData?.currentPage + 1);
              }}
              disabled={!tableData?.hasNextPage}
              aria-label="Previous"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
