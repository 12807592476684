import React, { useEffect, useState } from "react";
import TrackingHeaderAccordion from "./TrackingHeaderAccordion";
import CardPoolTrackingAccordion from "./CardPoolTrackingAccordion";
import SimpleBar from "simplebar-react";
import { EmployeeCardData } from "../../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";

interface Props {
  filteredEmployees: EmployeeCardData[];
  alignment: string;
  areAvatarsVisible: boolean;
  checkedItems: string[];
  handleCheckboxChange: (empId: string, event: any) => void;
  selectedDate: any;
  month: IMonthDropDown;
  year: IYearDropDown;
}
const TrackingFilterAccordion: React.FC<Props> = ({
  filteredEmployees,
  alignment,
  areAvatarsVisible,
  checkedItems,
  handleCheckboxChange,
  selectedDate,
  month,
  year,
}) => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [employees, setEmployees] = useState<EmployeeCardData[]>();
  const [empsByDesignation, setEmpsByDesignation] =
    useState<EmployeeCardData[]>();

  useEffect(() => {
    if (filteredEmployees?.length < 0) return;
    const alignedEmps = filteredEmployees?.filter((emp: EmployeeCardData) => {
      if (
        alignment === "aligned"
          ? emp?.isAligned === true
          : emp?.isAligned === false
      )
        return emp;
    });
    setEmployees(alignedEmps);
  }, [filteredEmployees]);

  useEffect(() => {
    if (!selectedFilter || (employees?.length as number) < 0) return;
    if (selectedFilter === "All") {
      setEmpsByDesignation(employees);
      return;
    }
    const withDesignation = employees?.filter((emp: EmployeeCardData) => {
      if (emp?.designation === selectedFilter) return emp;
    });
    setEmpsByDesignation(withDesignation);
  }, [employees, selectedFilter]);

  return (
    <>
      <div>
        <TrackingHeaderAccordion
          title="Pool Tracking"
          setSelectedFilter={setSelectedFilter}
        />
      </div>

      <SimpleBar
        className={`flex flex-col gap-3 ${areAvatarsVisible
          ? "h-[calc(100vh-648px)]"
          : "2xl:h-[calc(100vh-474px)] h-[calc(100vh-562px)]"}`}
      
      >
        <CardPoolTrackingAccordion
          empsByDesignation={empsByDesignation as EmployeeCardData[]}
          checkedItems={checkedItems}
          handleCheckboxChange={handleCheckboxChange}
          selectedDate={selectedDate}
          month={month}
          year={year}
        />
      </SimpleBar>
    </>
  );
};

export default TrackingFilterAccordion;
