import React, { useEffect, useState } from "react";
import {
  Chip,
  Input,
  Label,
  Modal,
  Radio,
  Select,
  Dropdown,
  MenuItem,
  PrevButton,
} from "@heathmont/moon-core-tw";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@heathmont/moon-core-tw/lib/es/button/Button";
import {
  ROLE,
  SENIORITY_LEVEL,
} from "../../../enums/super-admin/filters.enums";
import { getSections } from "../../../services/branch-admin-services/section.service";
import { set } from "react-hook-form";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";

interface EmployeeData {
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  joiningDate: Date | null;
  seniorityLevel: string;
  designation: string;
  hospital: string;
  branch: string;
  assignedSection: string;
  department: string | undefined;
  gender: string | undefined;
  address: string;
  isActive: boolean;
  temporaryRole: string;
  password?: string;
}

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  employeeData: EmployeeData;
  setEmployeeData: Function;
  sections: any;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  edit: boolean;
}
const EmployeeCreateEditModal: React.FC<IProps> = ({
  isOpen,
  closeModal,
  employeeData,
  setEmployeeData,
  handleSubmit,
  sections,
  edit,
}) => {
  const [passwordError, setPasswordError] = useState("");
  const contactNumberRegex = /^\+?[0-9]*$/;

  const [touched, setTouched] = useState<{
    [K in keyof EmployeeData]?: boolean;
  }>({});
  const [errors, setErrors] = useState<{ [K in keyof EmployeeData]?: string }>(
    {}
  );

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleBlur = (field: keyof EmployeeData) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateField(field);
  };

  const handleFocus = (field: keyof EmployeeData) => {
    setErrors((prev) => ({ ...prev, [field]: undefined }));
  };

  const validateField = (field: keyof EmployeeData) => {
    let error: any = null;
    if (!employeeData[field]) {
      error = "This field is required";
    }
    if (field === "email") {
      if (!emailRegex.test(employeeData.email)) {
        setErrors((prev) => ({ ...prev, [field]: "Required" }));
      }
      return;
    }
    if (field === "contactNo") {
      if (!contactNumberRegex.test(employeeData.contactNo)) {
        error = "Invalid contact number format";
      }
    }
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  const isFormValid = () => {
    if (!edit) {
      if (
        employeeData.designation === ROLE.BRANCH_ADMIN ||
        employeeData.designation === ROLE.NURSE_DIRECTOR
      ) {
        return (
          emailRegex.test(employeeData.email) &&
          contactNumberRegex.test(employeeData.contactNo) &&
          employeeData.employeeId !== "" &&
          employeeData.firstName !== "" &&
          employeeData.lastName !== "" &&
          employeeData.email !== "" &&
          employeeData.contactNo !== "" &&
          employeeData.joiningDate !== null &&
          employeeData.seniorityLevel !== "" &&
          employeeData.designation !== "" &&
          employeeData.gender !== "" &&
          employeeData.address !== "" &&
          employeeData.password !== ""
        );
      } else {
        return (
          emailRegex.test(employeeData.email) &&
          contactNumberRegex.test(employeeData.contactNo) &&
          employeeData.employeeId !== "" &&
          employeeData.firstName !== "" &&
          employeeData.lastName !== "" &&
          employeeData.email !== "" &&
          employeeData.contactNo !== "" &&
          employeeData.joiningDate !== null &&
          employeeData.seniorityLevel !== "" &&
          employeeData.designation !== "" &&
          employeeData.assignedSection !== "" &&
          employeeData.department !== "" &&
          employeeData.gender !== "" &&
          employeeData.address !== "" &&
          employeeData.password !== ""
        );
      }
    } else {
      return (
        emailRegex.test(employeeData.email) &&
        contactNumberRegex.test(employeeData.contactNo) &&
        employeeData.employeeId !== "" &&
        employeeData.firstName !== "" &&
        employeeData.lastName !== "" &&
        employeeData.email !== "" &&
        employeeData.contactNo !== "" &&
        employeeData.joiningDate !== null &&
        employeeData.seniorityLevel !== "" &&
        employeeData.designation !== "" &&
        employeeData.assignedSection !== "" &&
        employeeData.department !== "" &&
        employeeData.gender !== "" &&
        employeeData.address !== ""
      );
    }
  };

  useEffect(() => {
    setErrors({});
  }, [isOpen]);
  const passwordRegex = /.{8,32}$/;

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[50rem] bg-white rounded-xl overflow-x-hidden shadow-3xl max-h-[90dvh] overflow-y-auto">
          <div className="flex text-center flex-1 flex-col justify-center ">
            <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus sticky top-0 bg-white z-10">
              <div className="flex-1 text-start">
              <h3 className="text-moon-24 font-semibold">
              {edit ? "Edit" : "Add"} Employee
              </h3>
              <p className="text-base text-[#8697A2]">
                Fill the following fields to {edit ? "update" : "add"} the
                employee details
              </p>
              </div>
              <Button className="bg-transparent" onClick={closeModal}>
                  <ControlsCloseSmall className="w-6 h-6 text-[#000000]" />
                </Button>
             </div>
            <div className="mx-auto w-full py-6 px-4">
              <div className="mx-auto  w-full">
                <div className="flex flex-wrap -mx-1">
                  <div className="w-full px-1 mb-3">
                    <Label htmlFor="employeeId" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Employee ID
                    </Label>
                    <Input
                      disabled={edit}
                      type="text"
                      id="employeeId"
                      name="employeeId"
                      value={employeeData.employeeId}
                      onChange={(e) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          employeeId: e.target.value,
                        }))
                      }
                      placeholder="Employee ID"
                      className={`mt-1 block w-full px-3 py-2 border ${touched.employeeId && errors.employeeId
                          ? "border-red-500"
                          : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("employeeId")}
                      onFocus={() => handleFocus("employeeId")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="firstName" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      First Name
                    </Label>
                    <Input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={employeeData.firstName}
                      onChange={(e) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          firstName: e.target.value,
                        }))
                      }
                      placeholder="First Name"
                      className={`mt-1 block w-full px-3 py-2 border ${touched.firstName && errors.firstName
                          ? "border-red-500"
                          : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("firstName")}
                      onFocus={() => handleFocus("firstName")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="lastName" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Last Name
                    </Label>
                    <Input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={employeeData.lastName}
                      onChange={(e) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          lastName: e.target.value,
                        }))
                      }
                      placeholder="Last Name"
                      className={`mt-1 block w-full px-3 py-2 border ${touched.lastName && errors.lastName
                          ? "border-red-500"
                          : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("lastName")}
                      onFocus={() => handleFocus("lastName")}
                    />
                  </div>
                  <div className="w-full px-1 mb-3">
                    <Label htmlFor="gender" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Gender
                    </Label>
                    <Radio
                      value={employeeData.gender}
                      className="flex gap-4"
                      onChange={(e) => {
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          gender: e as string,
                        }));
                      }}
                    >
                      <Radio.Option value="male">
                        <Radio.Indicator className="moon-checked:border-primary after:bg-primary" />
                        Male
                      </Radio.Option>
                      <Radio.Option value="female">
                        <Radio.Indicator className="moon-checked:border-primary after:bg-primary" />
                        Female
                      </Radio.Option>
                    </Radio>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="email" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Email
                    </Label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      value={employeeData.email}
                      onChange={(e) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                      placeholder="Email"
                      className={`mt-1 block w-full px-3 py-2 border ${touched.email && errors.email
                          ? "border-red-500"
                          : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("email")}
                      onFocus={() => handleFocus("email")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="contactNo" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Contact No
                    </Label>
                    <Input
                      type="text"
                      id="contactNo"
                      name="contactNo"
                      value={employeeData.contactNo}
                      onChange={(e) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          contactNo: e.target.value,
                        }))
                      }
                      placeholder="Contact No"
                      className={`mt-1 block w-full px-3 py-2 border ${touched.contactNo && errors.contactNo
                          ? "border-red-500"
                          : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("contactNo")}
                      onFocus={() => handleFocus("contactNo")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="joiningDate" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Joining Date
                    </Label>
                    <div>
                      <ReactDatePicker
                        className={`mt-1 block w-full px-3 py-2 border ${touched.joiningDate && errors.joiningDate
                            ? "border-red-500"
                            : "border-gray-300"
                          } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm z-50 2xl:text-moon-16 text-moon-14`}
                        selected={employeeData.joiningDate}
                        onChange={(date) =>
                          setEmployeeData((prev: EmployeeData) => ({
                            ...prev,
                            joiningDate: date,
                          }))
                        }
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select date"
                        onBlur={() => handleBlur("joiningDate")}
                        onFocus={() => handleFocus("joiningDate")}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="seniorityLevel" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Seniority Level
                    </Label>
                    <Dropdown
                      value={employeeData.seniorityLevel}
                      onChange={(value) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          seniorityLevel: value as string,
                        }))
                      }
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select
                            open={open}
                            className="capitalize mt-1"
                          >
                          <span className="text-sm 2xl:text-base">
                              {employeeData.seniorityLevel === ""
                              ? "Seniority Level"
                              : employeeData.seniorityLevel}
                              </span>
                          </Dropdown.Select>
                          <Dropdown.Options>
                            {Object.values(SENIORITY_LEVEL).map((level) => (
                              <Dropdown.Option key={level} value={level}>
                                {({ selected, active }) => (
                                  <MenuItem
                                    isActive={active}
                                    isSelected={selected}
                                    className="capitalize"
                                  >
                                    <span className="2xl:text-base text-sm">{level}</span>
                                  </MenuItem>
                                )}
                              </Dropdown.Option>
                            ))}
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="designation" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Designation
                    </Label>
                    <Dropdown
                      disabled={edit}
                      value={employeeData.designation}
                      onChange={(value) =>
                        setEmployeeData((prev: EmployeeData) => {
                          if (
                            value === ROLE.BRANCH_ADMIN ||
                            value === ROLE.NURSE_DIRECTOR
                          ) {
                            return {
                              ...prev,
                              designation: value as string,
                              department: "All",
                              assignedSection: "All",
                            };
                          }
                          return {
                            ...prev,
                            designation: value as string,
                            department: "",
                          }; // Return the previous state if the condition is not met
                        })
                      }
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select open={open}>
                          <span  className="capitalize 2xl:text-moon-16 text-moon-14">
                          {employeeData.designation === ""
                              ? "Select Designation"
                              : employeeData.designation}
                          </span>
                          </Dropdown.Select>
                          <Dropdown.Options>
                            {Object.values(ROLE).map((level) => (
                              <Dropdown.Option key={level} value={level}>
                                {({ selected, active }) => (
                                  <MenuItem
                                    isActive={active}
                                    isSelected={selected}
                                    className="capitalize"
                                  >
                                    <span className="2xl:text-base text-sm">{level}</span>
                                      
                                  </MenuItem>
                                )}
                              </Dropdown.Option>
                            ))}
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>
                  {/* <div className="w-1/2 px-1 mb-3">
                      <Label htmlFor="hospital" className="text-start">
                        Hospital
                      </Label>
                      <Input
                        type="text"
                        id="hospital"
                        name="hospital"
                        value={employeeData.hospital}
                        onChange={(e) =>
                          setEmployeeData((prev) => ({
                            ...prev,
                            hospital: e.target.value,
                          }))
                        }
                        placeholder="Hospital"
                        className={`mt-1 block w-full px-3 py-2 border ${
                          touched.hospital && errors.hospital ? 'border-red-500' : 'border-gray-300'
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onBlur={() => handleBlur('hospital')}
                        onFocus={() => handleFocus('hospital')}
                      />
                    </div> */}
                  {/* <div className="w-1/2 px-1 mb-3">
                      <Label htmlFor="branch" className="text-start">
                        Branch
                      </Label>
                      <Input
                        type="text"
                        id="branch"
                        name="branch"
                        value={employeeData.branch}
                        onChange={(e) =>
                          setEmployeeData((prev) => ({
                            ...prev,
                            branch: e.target.value,
                          }))
                        }
                        placeholder="Branch"
                        className={`mt-1 block w-full px-3 py-2 border ${
                          touched.branch && errors.branch ? 'border-red-500' : 'border-gray-300'
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onBlur={() => handleBlur('branch')}
                        onFocus={() => handleFocus('branch')}
                      />
                    </div> */}
                  {/* <div className="w-1/2 px-1 mb-3">
                      <Label htmlFor="assignedSection" className="text-start">
                        Assigned Section
                      </Label>
                      <Input
                        type="text"
                        id="assignedSection"
                        name="assignedSection"
                        value={employeeData.assignedSection}
                        onChange={(e) =>
                          setEmployeeData((prev) => ({
                            ...prev,
                            assignedSection: e.target.value,
                          }))
                        }
                        placeholder="Assigned Section"
                        className={`mt-1 block w-full px-3 py-2 border ${
                          touched.assignedSection && errors.assignedSection ? 'border-red-500' : 'border-gray-300'
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onBlur={() => handleBlur('assignedSection')}
                        onFocus={() => handleFocus('assignedSection')}
                      />
                    </div> */}
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="department" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Department
                    </Label>
                    <Dropdown
                      disabled={
                        edit ||
                        employeeData.designation === ROLE.BRANCH_ADMIN ||
                        employeeData.designation === ROLE.NURSE_DIRECTOR
                      }
                      value={sections}
                      onChange={(value: any) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          assignedSection: value?._id as string,
                          department: value?.sectionName as string,
                        }))
                      }
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select open={open}>
                        <span className="text-sm 2xl:text-base">
                        {employeeData.designation === ROLE.BRANCH_ADMIN ||
                              employeeData.designation === ROLE.NURSE_DIRECTOR
                              ? "All Sections"
                              : employeeData.department === ""
                                ? "Select Section"
                                : employeeData.department}
                        </span>
                          </Dropdown.Select>
                          <Dropdown.Options>
                            {sections?.map((section: any) => (
                              <Dropdown.Option
                                key={section._id}
                                value={section}
                              >
                                {({ selected, active }) => (
                                  <MenuItem
                                    isActive={active}
                                    isSelected={selected}
                                  >
                                    
                                    <span className="2xl:text-base text-sm">
                                    {section.sectionName}
                                    </span>
                                  </MenuItem>
                                )}
                              </Dropdown.Option>
                            ))}
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>

                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="address" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                      Address
                    </Label>
                    <Input
                      type="text"
                      id="address"
                      name="address"
                      value={employeeData.address}
                      onChange={(e) =>
                        setEmployeeData((prev: EmployeeData) => ({
                          ...prev,
                          address: e.target.value,
                        }))
                      }
                      placeholder="Address"
                      className={`mt-1 block w-full px-3 py-2 border ${touched.address && errors.address
                          ? "border-red-500"
                          : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 -z-0 sm:text-sm`}
                      onBlur={() => handleBlur("address")}
                      onFocus={() => handleFocus("address")}
                    />
                  </div>
                  {!edit && (
                    <div className="w-1/2 px-1 mb-3">
                      <Label htmlFor="password" className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0">
                        Password
                      </Label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        value={employeeData.password}
                        onChange={(e) =>
                          setEmployeeData((prev: EmployeeData) => ({
                            ...prev,
                            password: e.target.value,
                          }))
                        }
                        placeholder="Password"
                        className={`mt-1 block w-full px-3 py-2 border ${passwordError ||
                            (!passwordRegex.test(
                              employeeData?.password as string
                            ) &&
                              employeeData.password)
                            ? "border-red-500"
                            : "border-gray-300"
                          } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onBlur={() => {
                          handleBlur("password");
                          if (
                            !passwordRegex.test(
                              employeeData?.password as string
                            ) &&
                            employeeData.password
                          ) {
                            setPasswordError(
                              `Password should be at least 8 characters long.`
                            );
                          }
                        }}
                        onFocus={() => {
                          handleFocus("password");
                          setPasswordError("");
                        }}
                      />
                      {passwordError && (
                        <p className="text-red-500 text-xs mt-1">
                          {passwordError}
                        </p>
                      )}
                    </div>
                  )}

                  {/* <div className="w-1/2 px-1 mb-3">
                      <Label htmlFor="isActive" className="text-start">
                        Active Status
                      </Label>
                      <Radio
                        id="isActive"
                        name="isActive"
                        value="active"
                        checked={employeeData.isActive}
                        onChange={() =>
                          setEmployeeData((prev) => ({
                            ...prev,
                            isActive: true,
                          }))
                        }
                      >
                        Active
                      </Radio>
                      <Radio
                        name="isActive"
                        value="inactive"
                        checked={!employeeData.isActive}
                        onChange={() =>
                          setEmployeeData((prev) => ({
                            ...prev,
                            isActive: false,
                          }))
                        }
                      >
                        Inactive
                      </Radio>
                    </div> */}

                  {/* <div className="w-1/2 px-1 mb-3">
                      <Label htmlFor="temporaryRole" className="text-start">
                        Temporary Role
                      </Label>
                      <Input
                        type="text"
                        id="temporaryRole"
                        name="temporaryRole"
                        value={employeeData.temporaryRole}
                        onChange={(e) =>
                          setEmployeeData((prev) => ({
                            ...prev,
                            temporaryRole: e.target.value,
                          }))
                        }
                        placeholder="Temporary Role"
                        className={`mt-1 block w-full px-3 py-2 border ${
                          touched.temporaryRole && errors.temporaryRole ? 'border-red-500' : 'border-gray-300'
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onBlur={() => handleBlur('temporaryRole')}
                        onFocus={() => handleFocus('temporaryRole')}
                      />
                    </div> */}
                </div>
                
                {/* <div className="w-full px-1 mb-3">
                  <Button
                    onClick={(e:any)=>handleSubmit(e)}
                    disabled={isFormValid() ?false:true}
                    className="w-full h-10 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"                   
                  >
                    {edit ? 'Update':'Create'}
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="modal-footer bg-white py-2 px-4 flex items-center justify-end gap-[8px]">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="tertiary"
                    onClick={(e: any) => handleSubmit(e)}
                    disabled={isFormValid() ? false : true}
                    className=" text-white rounded-md shadow-md focus:outline-none focus:ring-2  focus:ring-opacity-50"
                  >
                    {edit ? "Update" : "Create"}
                  </Button>
                </div>
            {/* <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <Button
                  type="submit"
                  disabled={!isFormValid}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Save
                </Button>
                <Button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                //   onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
              </div> */}
          </div>
      </Modal.Panel>
    </Modal>
  );
};

export default EmployeeCreateEditModal;
