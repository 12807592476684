import { Button, Form, Input, Label } from "@heathmont/moon-core-tw";
import React, { useEffect, useState } from "react";
// import authSvc from "../../services/authService";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/auth.service";
import {
  setSnackBar,
  setToastMessage,
} from "../../features/ToastSlice/toastSlice";
import { store } from "../../store/store";
interface Email {
  email: string;
}

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<Email>({
    email: "",
  });
  const [email, setEmail] = useState("");

  const [emailError, setEmailError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const emailRegex = /^(?!.*\s)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const inputChangeHandler = (event: any) => {
    setCredentials((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!email.match(emailRegex)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Your API call to perform login goes here
    // You can use the 'email' and 'password' states to send the data to your backend
    onSubmit(email);
    // Clear the form after submission
  };

  const onSubmit = async (event: any) => {
    const res: any = await resetPassword(email);

    if (res?.mailSent) {
      navigate("/email-sent");
    } else if (res?.err) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Email does not exist"));
    }
    console.log("RES", res);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    if (formSubmitted && !emailValue?.match(emailRegex)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      <div className="h-screen flex flex-col justify-between bg-form-pattern bg-no-repeat bg-center p-4 bg-cover">
        <div className="flex text-center flex-1  flex-col justify-center ">
          <div className="mx-auto max-w-1/2 pt-12 w-full">
            <img
              className="mx-auto mb-20"
              src={"images/login-weblogo.png"}
              alt="logo"
            />
            <h3 className="text-3xll mb-5">Forgot Your Password</h3>
            <p className="text-base text-[#8697A2] mb-10">
              Enter your registered email address to reset the password
            </p>
            <form
              className="mx-auto py-9 px-10 bg-white rounded-xl shadow-3xl"
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              <div className="mb-3">
                <Label htmlFor="email" className="text-start">
                  Email Address
                </Label>
                <Input
                  type="text" //can be set as email too
                  value={email}
                  placeholder="Email Address"
                  onChange={(event) => {
                    handleEmailChange(event);
                  }}
                  autoFocus
                />
                {formSubmitted && emailError && (
                  <p style={{ color: "red" }}>{emailError}</p>
                )}
              </div>

              <Button
                className="w-full h-10 mb-5 shadow-btnshadow "
                type="submit"
                // form="login"
                // value="Submit"
                variant="tertiary"
                // onClick={onSubmit}
                disabled={email.match(emailRegex) ? false : true}
              >
                Verify
              </Button>
              <Link to="#" className="text-base text-[#8697A2] cursor-pointer">
                Send email to verify your account
              </Link>
            </form>
          </div>
        </div>
        <hr />
        <div className="footer px-24 py-6">
          <div className="flex justify-between items-center">
            <p className="text-base text-[#8697A2]">
              Copyright ©
              <span className="text-[#249CBA] mx-1">
                Healthcare Management Portal |
              </span>
              All Rights Reserved
            </p>
            <img src={"images/healthlogo.svg"} alt="logo" />
          </div>
        </div>
      </div>
    </>
  );
}
