import React from "react";

const EmailSent = () => {
  return (
    <div className="h-screen flex flex-col justify-between bg-form-pattern bg-no-repeat bg-center p-4 bg-cover">
      <div className="flex text-center flex-1  flex-col justify-center ">
        <div className="mx-auto max-w-1/2 pt-12">
          {" "}
          <img
            className="mx-auto mb-20"
            src={"images/login-weblogo.png"}
            alt="logo"
          />
          <div className="mx-auto py-9 px-10 bg-white rounded-xl shadow-3xl">
            <h3 className="text-moon-32 mb-5 font-semibold">Email Sent!</h3>
            <p className="text-base mb-10 text-[#8697A2]">
              An email has been sent to you. Access it to reset your password.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer px-24 py-6">
        <div className="flex justify-between items-center">
          <p className="text-base text-[#8697A2]">
            Copyright ©
            <span className="text-[#249CBA] mx-1">
              Healthcare Management Portal |
            </span>
            All Rights Reserved
          </p>
          <img src={"images/healthlogo.svg"} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default EmailSent;
