import React from "react";
import { Checkbox, Avatar, Chip } from "@heathmont/moon-core-tw";
import { NavLink } from "react-router-dom";
import { GenericInfo } from "@heathmont/moon-icons-tw";

interface Employee {
  name: string;
  imageUrl: string;
  role: string;
  daysLeft: number;
  hoursSpent: number;
  onLeave: boolean;
  onRest: boolean;
}

interface EmployeeCardListingProps {
  employees: Employee[];
  checked: any;
  handleCheckboxChange: (index: number) => void;
}


const EmployeeCardListing = ({ employees, checked, handleCheckboxChange }: EmployeeCardListingProps) => {
  return (
    <>
      {employees.map((employee: Employee, index:any) => (
        <div
          key={index}
          className={`relative after:content-['']  after:-left-[4px] after:absolute after:top-[1px] after:bottom-[1px] after:right-0 ${
            checked[index] && employee.role === "Nurse"
              ? "after:bg-cyan-500"
              : checked[index] && employee.role === "Leader"
              ? "after:bg-pink-500"
              : "after:bg-transparent"
          } after:rounded-[12px]  after:w-full`}
        >
          <div
            className={`flex items-center flex-row p-[0px_8px] my-[5px] rounded-[8px] relative z-10
            ${
              checked[index] && employee.role === "Nurse"
                ? "bg-cyan-50"
                : checked[index] && employee.role === "Leader"
                ? "bg-pink-50"
                : "bg-white"
            }

            relative z-10`}
          >
            <div
              className={`flex-[0_0_50px] p-[8px] ${
                employee.onLeave || employee.onRest ? "disable" : ""
              }`}
            >
              <Checkbox
                bgColor="bg-cyan-600"
                checked={checked[index]}
                onChange={() => handleCheckboxChange(index)}
              />
            </div>
            <div className="flex-[0_0_260px] p-[8px]">
              <div className="flex items-center gap-[15px]">
                <span className="inline-flex rounded-full shadow-md">
                  <Avatar
                    size="sm"
                    className="rounded-full border-2 overflow-hidden"
                    imageUrl={employee.imageUrl}
                  ></Avatar>
                </span>
                <div className="flex-1">
                  <div className="flex justify-items-center gap-[6px]">
                    <Chip
                      size="sm"
                      className={`text-moon-9 ${
                        employee.role === "Nurse"
                          ? "text-cyan-500 bg-cyan-200"
                          : employee.role === "Leader"
                          ? "text-pink-500 bg-pink-200"
                          : "text-cyan-500 bg-cyan-200"
                      } rounded-sm px-[8px] py-0 h-auto uppercase font-semibold ${
                        employee.onLeave || employee.onRest ? "disable" : ""
                      }`}
                    >
                      {employee.role}
                    </Chip>
                    {employee.onLeave && (
                      <Chip
                        size="sm"
                        className="text-moon-9 text-pink-500 bg-pink-100 rounded-sm px-[8px] py-0 h-auto tracking-[1px] font-semibold uppercase"
                      >
                        On Leave
                      </Chip>
                    )}
                    {employee.onRest && (
                      <Chip
                        size="sm"
                        className="text-moon-9 text-gray-600 bg-gray-100 rounded-sm px-[8px] py-0 h-auto tracking-[1px] font-semibold uppercase"
                      >
                        REST TIME
                      </Chip>
                    )}
                  </div>
                  <h3
                    className={`text-bulma line-clamp-1 2xl:text-moon-16 text-moon-14  ${
                      employee.onLeave || employee.onRest ? "disable" : ""
                    }`}
                  >
                    {employee.name}
                  </h3>
                </div>
              </div>
            </div>
            <div
              className={`flex-[0_0_90px] p-[8px] ${
                employee.onLeave || employee.onRest ? "disable" : ""
              }`}
            >
              <h4 className="text-moon-12 text-gray-400">Days Left</h4>
              <p className="2xl:text-moon-16 text-moon-14  text-bulma font-semibold">
                {employee.daysLeft}
              </p>
            </div>
            <div
              className={`flex-1 p-[8px] ${
                employee.onLeave || employee.onRest ? "disable" : ""
              }`}
            >
              <h4 className="text-moon-12 text-gray-400">Hours Spent</h4>
              <p className="2xl:text-moon-16 text-moon-14  text-bulma font-semibold">
                {employee.hoursSpent}{" "}
                <span className="font-normal text-moon-12">/217</span>
              </p>
            </div>
            <div className="p-[8px]">
              <NavLink to="#" className="inline-flex items-center text-moon-12">
                View info{" "}
                <span className="ml-2 2xl:text-moon-16 text-moon-14 ">
                  <GenericInfo />
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default EmployeeCardListing;
