import React from "react";

const ProfileHeader = () => {
  return (
    <div className="sm:px-7 px-4 bg-hit min-h-[118px]">
      <div className="flex items-center py-[23px]  -mx-[16px]">
        <div className="w-1/4 px-[16px]">
          <div>
            <h3 className="text-[32px] font-semibold -tracking-[0.5px] text-white  mb-8px">
              Profile
            </h3>
            <p className="text-white text-[16px] ">
              Here is an information about yourself
            </p>
          </div>
        </div>
        <div className="w-3/4  px-[16px]"></div>
      </div>
    </div>
  );
};

export default ProfileHeader;
