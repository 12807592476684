import React, { useState, JSX } from "react";
import { IconButton, Switch } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons";

type Column = {
  Header: () => JSX.Element;
  accessor: keyof DataRow; // Using DataRow type here
};

type DataRow = {
  role: string;
  order: JSX.Element;
  permissions: string[];
};

const UserListing = (props: any) => {
  console.log("Props", props);
  const columnsInitial: Column[] = [
    {
      Header: () => <div className="font-bold">User Role</div>,
      accessor: "role",
    },
    {
      Header: () => <div className="font-bold">Order</div>,
      accessor: "order",
    },
  ];

  const [collapsedRows, setCollapsedRows] = useState<number[]>(
    props?.roles?.map((_: any, index: any) => index)
  );
  const [state, setState] = useState(true);

  const handleCollapseRow = (rowIndex: number) => {
    if (collapsedRows.includes(rowIndex)) {
      setCollapsedRows(collapsedRows.filter((index) => index !== rowIndex));
    } else {
      setCollapsedRows([...collapsedRows, rowIndex]);
    }
  };

  return (
    <div className="tableResponsive bg-[#F5F5F5] p-[8px] rounded-[8px]">
      <table className="w-full">
        <thead>
          <tr>
            {columnsInitial.map((column, columnIndex) => (
              <th
                className="text-left text-moon-16 text-[#000] font-normal"
                key={columnIndex}
              >
                {column.Header()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props?.roles?.map((role: any, rowIndex: any) => {
            const isCollapsed = collapsedRows.includes(rowIndex);
            return (
              <React.Fragment key={rowIndex}>
                <tr>
                  <td className="capitalize">{role.role}</td>
                  <td>
                    <div className="flex items-center justify-between">
                      {role.order}
                      <div>
                        <IconButton
                          variant="ghost"
                          size="sm"
                          icon={<ControlsChevronDown />}
                          onClick={() => handleCollapseRow(rowIndex)}
                          className={`transition-transform duration-200 focus:bg-transparent ${
                            collapsedRows.includes(rowIndex) ? "" : "rotate-180"
                          }`}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                {!isCollapsed && (
                  <tr>
                    <td colSpan={columnsInitial.length}>
                      <div className="text-left p-[15px_30px_15px_56px]">
                        <h4 className="text-hit font-semibold mb-[18px]">
                          Access Permissions
                        </h4>
                        {role.permissions.map((permission: any, index: any) => (
                          <div className="flex gap-4 mb-[18px]" key={index}>
                            <div className="flex-1 text-moon-14 text-gray-600">
                              {permission}
                            </div>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserListing;
