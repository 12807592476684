import React from "react";
import TrackingTabs from "./TrackingTabs";

const items: string[] = ["All", "Senior Nurse", "Nurse", "HCA"];

interface Props {
  title: string;
}
const TrackingHeader: React.FC<Props> = ({ title }) => {
  return (
    <div className="bg-goku ppy-8  px-4  rounded-[8px] 2xl:flex items-center">
      <h3 className="text-moon-14 font-semibold">{title}</h3>

      <div className="2xl:ml-auto">
        <TrackingTabs items={items} />
      </div>
    </div>
  );
};

export default TrackingHeader;
