import { useState } from "react";
import { Modal, Button, IconButton } from "@heathmont/moon-core-tw";
import {
  ControlsCloseSmall,
  GenericAlarmRound,
  GenericDelete,
  GenericEdit,
  TimeCalendarDate,
} from "@heathmont/moon-icons-tw";
import { DraftData, Drafts } from "../../Calendar/full-month-view/header-month/TopbarMonth";
import moment from "moment";
import OverlappedAvatar from "../../UI/OverlappedAvatar";
import ConfirmationModal from "../../../confirmation-modal/confirmationModal";
type Options = {
  value: string;
  label: string;
};

interface Props {
  isOpen: boolean;
  setIsOpen: (check: boolean) => void;
  draftsOfSchedules: Drafts;
  removeDraft: (draftId: string) => void;
  draftId: string;
  setDraftInfo: (id: DraftData) => void;
  loadEditDraft: boolean;
  setLoadEditDraft: (check: boolean) => void;
  submitForSchedule: (draftId: string) => void;
  setModalToExpand: (modal: string[]) => void;
}

const ViewDraftsModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  draftsOfSchedules,
  removeDraft,
  draftId,
  setDraftInfo,
  loadEditDraft,
  setLoadEditDraft,
  submitForSchedule,
  setModalToExpand,
}) => {
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);
  //   const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  // const month = moment()

  // const closeConfirmationModal = () => setIsOpen(false);
  // const openConfirmationModal = () => setIsOpen(true);
  return (
    <>
      <ConfirmationModal
        isOpenConfirmation={isOpenConfirmation}
        setIsOpenConfirmation={setIsOpenConfirmation}
        message="Are you sure you want to delete?"
        actionFunction={removeDraft}
        sendBackParam={draftId}
      />
      <Modal open={isOpen} onClose={closeModal}>
        <Modal.Backdrop />
        <Modal.Panel className="viewdrafts">
          <div className="border-b-[0.063rem] flex items-center justify-between border-beerus py-3 px-6 relative">
            <h3 className="text-moon-18 text-bulma font-medium">View Drafts</h3>
            <Button
              variant="ghost"
              size="sm"
              className="absolute top-2 end-5"
              onClick={closeModal}
            >
              <ControlsCloseSmall className="text-moon-24" />
            </Button>
          </div>
          {draftsOfSchedules?.drafts?.length === 0 ? (
            <div className="flex min-h-[480px] items-center justify-center">
              <img
                className="my-2"
                src={"images/no-drafts.svg"}
                // src=""
                alt="No Draft is available"
              />
            </div>
          ) : (
            <div className="viewdrftcontent p-6 ">
              <p className="text-trunks 2xl:text-moon-16 text-moon-14  mb-4">
                Total Schedules in Draft{" "}
                <span className="text-white text-moon-14 bg-blue-400 p-1 rounded-full px-2">
                  {draftsOfSchedules?.drafts?.length || 0}
                </span>
              </p>
              {draftsOfSchedules?.drafts?.map((draft) => (
                <div
                  key={draft?._id}
                  className="bg-gray-100 p-4 rounded-xl my-2"
                >
                  <div className="flex justify-between items-start">
                    <div className="flex items-center">
                      <span className="rounded-full flex items-center justify-center bg-blue-50 p-2.5 w-[50px] h-[50px] me-4">
                        <TimeCalendarDate className="text-moon-24 text-whis" />
                      </span>
                      <div>
                        <p className="text-xs">Draft Schedule</p>
                        <h4 className="text-xl">
                          {moment()
                            ?.month(draft?.month - 1)
                            ?.format("MMM") +
                            " " +
                            moment()?.year(draft?.year)?.format("YYYY")}
                        </h4>
                      </div>
                    </div>
                    <p className="text-moon-14 text-trunks"></p>
                  </div>
                  <div className="flex justify-between items-center h-4">
                    {/* <div className="pt-1"> */}
                    {/* <OverlappedAvatar /> */}
                    <p className="text-moon-14 text-trunks">
                      Schedule Version:
                      <span className="text-moon-14 text-black ms-1">
                        {draft?.scheduleVersion}
                      </span>
                    </p>
                    {/* </div> */}
                    <div className="flex items-center">
                      <Button
                        variant="ghost"
                        className="p-2"
                        onClick={() => {
                          setDraftInfo(draft);
                          setLoadEditDraft(!loadEditDraft);
                          setModalToExpand(["item-2"]);
                          closeModal();
                        }}
                      >
                        <GenericEdit className="text-moon-20" />{" "}
                      </Button>
                      <Button
                        variant="ghost"
                        className="p-2"
                        onClick={() => {
                          // removeDraft(draft?._id);
                          setDraftInfo(draft);
                          setIsOpenConfirmation(true);
                        }}
                      >
                        <GenericDelete className="text-moon-20" />
                      </Button>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-3">
                    <p className="text-moon-12 text-trunks">
                      Created on
                      <span className="text-moon-12 text-black ms-1">
                        {moment(draft?.createdAt).format("DD MMM YYYY")}
                      </span>
                    </p>
                    <div className="flex items-center">
                      <Button
                        className="px-3 h-8 py-1 gap-1 text-moon-14 rounded-moon-i-sm font-medium btn-tertiary me-1"
                        onClick={() => {
                          submitForSchedule(draft?._id);
                          closeModal();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="bg-goku py-4 px-3 rounded-lg mb-9">
              <div className="flex justify-between items-start">
                <div className="flex justify-normal items-center">
                  <span className="rounded-full flex items-center justify-center bg-blue-50 p-2.5 w-[50px] h-[50px] me-4">
                    <TimeCalendarDate className="text-moon-24 text-whis" />
                  </span>
                  <div>
                    <p className="text-xs">Draft Schedule</p>

                    <h4 className="text-xl">{draftName || "Title"}</h4>
                    <GroupAvatar
                      avatars={avatars}
                      overflowCount={overflowCount}
                    />
                  </div>
                </div>
                <p>{date?.month} 2023</p>
              </div>
            </div> */}
            </div>
          )}

          <div className="flex gap-2 p-4 justify-end pt-2 absolute inset-x-0 bottom-0">
            <div className="flex-1 flex items-center  text-gray-600 text-[12px]">
              <GenericAlarmRound className="text-[18px] mr-[6px]" />
              <p>You can edit or delete any schedule in draft here</p>
            </div>
            <Button
              className="px-4 h-10 py-2 text-moon-14 rounded-moon-i-sm font-medium btn-secondary"
              onClick={closeModal}
            >
              Close
            </Button>
            {/* <Button onClick={closeModal}>Create</Button> */}
          </div>
        </Modal.Panel>
      </Modal>
      {/* <ConfirmationModal
      isOpen={isOpen}
      closeModal={closeConfirmationModal}
      openModal={openConfirmationModal}
      /> */}
    </>
  );
};

export default ViewDraftsModal;
