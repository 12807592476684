import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons-tw";
import { useDispatch, useSelector } from "react-redux";
import { setSection } from "../../../features/nurse-manager/Section Slice/sectionSlice";
import { RootState } from "../../../store/store";
import { IUserData } from "../../../types/IUserData.interface";
import SimpleBar from "simplebar-react";
interface Option {
  name: string;
  currentSchedules: number;
  totalSchedules: number;
  scheduledEmployees: number;
  presentEmployees: number;
  totalEmployees: number;
}

// const options: Option[] = [
//   {
//     name: "Orthopedics",
//     currentSchedules: 26,
//     totalSchedules: 30,
//     scheduledEmployees: 26,
//     presentEmployees: 121,
//     totalEmployees: 240,
//   },
//   {
//     name: "ICU",
//     currentSchedules: 37,
//     totalSchedules: 45,
//     scheduledEmployees: 15,
//     presentEmployees: 80,
//     totalEmployees: 180,
//   },
//   {
//     name: "Pediatrics",
//     currentSchedules: 22,
//     totalSchedules: 35,
//     scheduledEmployees: 19,
//     presentEmployees: 98,
//     totalEmployees: 200,
//   },
//   {
//     name: "CCU",
//     currentSchedules: 15,
//     totalSchedules: 20,
//     scheduledEmployees: 10,
//     presentEmployees: 55,
//     totalEmployees: 120,
//   },
//   {
//     name: "Gynecology",
//     currentSchedules: 12,
//     totalSchedules: 25,
//     scheduledEmployees: 8,
//     presentEmployees: 75,
//     totalEmployees: 150,
//   },

// ];

interface Section {
  _id: string;
  sectionName: string;
}
interface Props {
  sections?: Section;
  options: Section[];
  onChangeSection: (data: Section) => void;
}
const SectionMenu: React.FC<Props> = ({
  sections,
  options,
  onChangeSection,
}) => {
  const dispatch = useDispatch();
  // const section = useSelector((state: RootState) => state.section);
  const handleOptionChange = (selectedItem: any) => {
    // dispatch(setSection(selectedItem));
  };
  const [userData, setUserData] = useState<IUserData>();
  // const [option, setSelectedOption] = useState<any>();
  // useEffect(() => {
  //   let user: string = sessionStorage.getItem("user") as string;
  //   if (user) {
  //     const jsonData: IUserData = JSON.parse(user);
  //     setUserData(jsonData);
  //   } else {
  //   }
  // }, []);

  const User = JSON?.parse(sessionStorage?.getItem("user")!);
  // useEffect(() => {}, [sections]);

  if (User?.designation !== "nurse director") {
    return (
      <div className="2xl:text-3xll text-2xl text-white mb-[5px] mr-[15px]">
        {User?.department}
      </div>
    );
  }

  return (
    // <div className="text-3xll text-white mb-[5px] mr-[15px]">
    //   {userData?.sectionName}
    // </div>
    <div className="relative z-50 2xl:text-3xll text-2xl text-white mb-[5px] mr-[15px]">
      <Dropdown
        value={sections}
        onChange={(item: any) => {
          onChangeSection(item);
          handleOptionChange(item);
        }}
      >
        <Dropdown.Trigger>
          <div className="cursor-pointer transition-colors flex items-center">
            <h3 className="2xl:text-3xll text-2xl text-white mb-[5px] mr-[15px]">
              {sections?.sectionName}
            </h3>
            <ControlsChevronDown className="text-white" />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Options className="dropMenu min-w-[215px] max-w-[215px]">
          
        <SimpleBar className=" max-h-[200px] overflow-y-auto">
          {/* <h4 className="text-moon-14 text-grey mb-[5px] pt-2 px-2">Section</h4> */}
          {options?.map((menuItem: any, index: number) => (
            <Dropdown.Option value={menuItem} key={index}>
              {({ selected, active }) => (
                <MenuItem
                  isActive={active}
                  isSelected={selected}
                  onClick={() => handleOptionChange(selected)}
                  className={`${
                    selected || active
                      ? "bg-goku text-cyan-500"
                      : "text-gray-900"
                  } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
                >
                  {menuItem?.sectionName}
                </MenuItem>
              )}
            </Dropdown.Option>
          ))}
          </SimpleBar>
        </Dropdown.Options>
      </Dropdown>
    </div>
  );
};

export default SectionMenu;
