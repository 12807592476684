// import React, { useEffect, useState, useRef } from "react";
// import {
//   Button,
//   IconButton,
//   Input,
//   Label,
//   Modal,
// } from "@heathmont/moon-core-tw";
// import { ControlsClose, GenericPicture } from "@heathmont/moon-icons";
// import DataSelect from "./DataSelect ";
// import { setHospital } from "../../../../services/hospital.service";
// import { IHospitalInfo } from "../../../../types/interfaces/IHospitalInfo.interface";

// interface ModalAddRoleProps {
//   isOpen: boolean;
//   closeModal: () => void;
//   fetchData: () => void;
//   editData: any;
// }

// const ModalAddRole: React.FC<ModalAddRoleProps> = ({
//   isOpen,
//   closeModal,
//   fetchData,
//   editData,
// }) => {
//   const fileInputRef = useRef(null);
//   const [hospitalName, setHospitalName] = useState<string>("");
//   const [hospitalLogo, setHospitalLogo] = useState<FormData | null>(null);
//   const [selectedImagePreview, setSelectedImagePreview] = useState<
//     string | null
//   >(editData?.logo);

//   const inputChangeHandler = (event: any) => {
//     setHospitalName(event.target.value);
//   };

//   const uploadImage = (e: any) => {
//     const logo = e.target.files[0];

//     if (logo) {
//       const formData = new FormData();
//       formData.append("logo", logo);
//       setSelectedImagePreview({ ...editData.logo });
//       setHospitalLogo(formData);
//     }
//   };

//   const onUploadData = async () => {
//     if (hospitalLogo && hospitalName !== "") {
//       const params: IHospitalInfo = {
//         name: hospitalName as string,
//         logo: hospitalLogo.get("logo") as string,
//       };

//       const response = await setHospital(params);
//       if (response) {
//         fetchData();
//       }
//     }
//   };

//   useEffect(() => {
//     console.log("Name", hospitalName);
//     console.log("Logo", hospitalLogo);
//   }, [hospitalLogo, hospitalName]);

//   return (
//     <Modal open={isOpen} onClose={closeModal}>
//       <Modal.Backdrop />
//       <Modal.Panel className="max-w-[500px]">
//         <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
//           <h3 className="text-moon-20 font-semibold">Add Hospital</h3>

//           <IconButton
//             onClick={closeModal}
//             variant="ghost"
//             icon={<ControlsClose />}
//           />
//         </div>
//         <div className="modal-content px-4 py-2  border-b border-beerus">
//           <div className="w-full mb-[24px]">
//             <Label htmlFor="#" className="2xl:text-moon-16 text-moon-14  text-[#000]">
//               Name of the Hospital{" "}
//             </Label>
//             <Input
//               type="text"
//               placeholder="e.g, Hamilton Hospital"
//               value={editData?.name}
//               name="name"
//               onChange={inputChangeHandler}
//             />
//           </div>
//           <div className="w-full">
//             <Label htmlFor="#" className="2xl:text-moon-16 text-moon-14  text-[#000]">
//               Upload Logo
//             </Label>
//             <input
//               type="file"
//               id="file"
//               hidden
//               name="logo"
//               ref={fileInputRef}
//               onChange={uploadImage}
//               accept=".png, .jpg, .jpeg"
//             />
//             <Label
//               htmlFor="file"
//               className="flex flex-col items-center justify-center border border-dashed border-gray-300 p-4 rounded-lg cursor-pointer"
//             >
//               <span className="inline-flex text-gray-600 2xl:text-moon-14 text-moon-12 ">
//                 {selectedImagePreview ? (
//                   <img
//                     src={selectedImagePreview}
//                     alt="Selected"
//                     width="100"
//                     height="100"
//                   />
//                 ) : (
//                   "Drop file to upload"
//                 )}
//               </span>
//               <span className="inline-flex text-gray-600 2xl:text-moon-14 text-moon-12 ">Or</span>
//               <GenericPicture className="text-moon-32" />
//             </Label>
//           </div>
//         </div>
//         <div className="modal-footer  px-4 py-2  flex items-center justify-end gap-[8px]">
//           <Button variant="secondary" onClick={closeModal}>
//             Discard
//           </Button>
//           <Button variant="tertiary" onClick={onUploadData}>
//             Add
//           </Button>
//         </div>
//       </Modal.Panel>
//     </Modal>
//   );
// };

// export default ModalAddRole;

import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Input,
  Label,
  Modal,
} from "@heathmont/moon-core-tw";
import { ControlsClose, GenericPicture } from "@heathmont/moon-icons";
import DataSelect from "./DataSelect ";
import { setHospital } from "../../../../../services/super-admin-services/hospital.service";
import { IHospitalInfo } from "../../../../../types/super-admin-interfaces/IHospitalInfo.interface";
import { url } from "inspector";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../../features/ToastSlice/toastSlice";
import { store } from "../../../../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { LazyLoadImage } from "react-lazy-load-image-component";

interface ModalAddRoleProps {
  isOpen: boolean;
  closeModal: () => void;
  fetchData: () => void;
  editData: any;
  editHospitalData?: (data: any) => void;
}

const ModalAddRole: React.FC<ModalAddRoleProps> = ({
  isOpen,
  closeModal,
  fetchData,
  editData,
  editHospitalData,
}) => {
  const data: any = {
    name: editData?.name,
    logo: editData?.logo,
  };

  const [hospitalData, setHospitalData] = useState<IHospitalInfo>(
    {} as IHospitalInfo,
  );

  console.log("Hosp", data);
  const [selectedImagePreview, setSelectedImagePreview] = useState<
    string | null
  >(null);
  const inputChangeHandler = (event: any) => {
    setHospitalData((prev: any) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };
  console.log("Edit", editData);
  const uploadImage = (e: any) => {
    const logo = e.target.files[0];

    if (logo) {
      const formData = new FormData();
      formData.append("logo", logo);
      const reader = new FileReader();
      reader.onload = (event: any) => {
        setSelectedImagePreview(event.target.result as string);
      };
      reader.readAsDataURL(logo);
      hospitalData.logo = formData?.get("logo");
      console.log("FormData", formData.get("logo"));
      setHospitalData((prev: any) => {
        return { ...prev, logo: formData.get("logo") };
      });
    }
  };

  const onUploadData = async () => {
    if (/^\S.+$/.test(hospitalData?.name)) {
      // const params: IHospitalInfo = {
      //   name: hospitalName as string,
      //   logo: hospitalLogo?.get("logo") as string,
      // };
      console.log("hospitalData", hospitalData);
      const response: any = await setHospital(hospitalData);

      if (response?.res) {
        fetchData();
        closeModal();
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Hospital Added Successfully"));
        setSelectedImagePreview(null);
      } else {
        // closeModal();
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage("Please Enter Valid Data"));
      }
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please Enter Valid Data"));
    }
  };

  useEffect(() => {
    if (editData) {
      setHospitalData({ ...data });
    }
  }, [editData, isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        setSelectedImagePreview(null);
      }}
    >
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[500px]">
        <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
          <h3 className="text-moon-20 font-semibold">
            {editData?.name ? "Edit Hospital" : "Add Hospital"}
          </h3>

          <IconButton
            onClick={() => {
              closeModal();
              setSelectedImagePreview(null);
            }}
            variant="ghost"
            icon={<ControlsClose />}
          />
        </div>
        <div className="modal-content px-4 py-2  border-b border-beerus">
          <div className="w-full mb-[24px]">
            <Label htmlFor="#" className="2xl:text-moon-16 text-moon-14  text-[#000]">
              Name of the Hospital{" "}
            </Label>
            <Input
              type="text"
              placeholder="e.g, Hamilton Hospital"
              value={hospitalData?.name}
              name="name"
              onChange={inputChangeHandler}
              autoFocus={true}
            />
          </div>
          <div className="w-full">
            <Label htmlFor="#" className="2xl:text-moon-16 text-moon-14  text-[#000]">
              Upload Logo
            </Label>

            <Label
              htmlFor="file"
              className="flex flex-col items-center justify-center border border-dashed border-gray-300 p-4 rounded-lg"
            >
              <span className="inline-flex text-gray-600 2xl:text-moon-14 text-moon-12 ">
                Drop file to upload
              </span>
              <span className="inline-flex text-gray-600 2xl:text-moon-14 text-moon-12 ">Or</span>
              <GenericPicture className="text-moon-32" />
            </Label>
            <input
              type="file"
              id="file"
              hidden
              name="logo"
              onChange={uploadImage}
              multiple
              accept=".png, .jpg, .jpeg"
            />
            {
              // selectedImagePreview !== null && hospitalData?.logo !== "" ? (
              //   <div className="mb-4 block w-24 h-24">
              //     <img
              //       src={selectedImagePreview ?? hospitalData?.logo}
              //       alt="Hospital Logo"
              //       onChange={uploadImage}
              //       className="w-full h-full object-cover"
              //     />
              //   </div>
              // ) : //  :
              selectedImagePreview !== null || (editData && editData?.logo) ? (
                <div className="mb-4 block w-24 h-24">
                  <LazyLoadImage
                    src={selectedImagePreview ?? hospitalData?.logo}
                    className="w-full h-full object-cover"
                    alt="Hospital Logo 2"
                    onChange={uploadImage}
                  />
                  {/* <img
                    src={selectedImagePreview ?? hospitalData?.logo}
                    alt="Hospital Logo 2"
                    className="w-full h-full object-cover"
                    onChange={uploadImage}
                  /> */}
                </div>
              ) : (
                ""
              )
            }
          </div>
        </div>
        <div className="modal-footer  px-4 py-2  flex items-center justify-end gap-[8px]">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
              setSelectedImagePreview(null);
            }}
          >
            Discard
          </Button>
          {editData && editData?.name ? (
            <Button
              variant="tertiary"
              onClick={() => {
                editHospitalData && editHospitalData(hospitalData);
                setHospitalData({} as IHospitalInfo);
                setSelectedImagePreview(null);
                closeModal();
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="tertiary"
              onClick={() => {
                onUploadData();
                // closeModal();
              }}
            >
              Add
            </Button>
          )}
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default ModalAddRole;
