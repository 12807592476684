import { useState } from "react";
import PageTitle from "../../../Components/super-admin/ui/PageTitle";
import { MenuItem } from "@heathmont/moon-core-tw";
import HospitalSettings from "./hospital";
import UserSettings from "./user";
const Home = () => {
  const [activePage, setActivePage] = useState("hospital-setting");

  const handleUsersClick = () => {
    setActivePage("user-setting");
  };
  const handleHospitalSettingClick = () => {
    setActivePage("hospital-setting");
  };

  return (
    <>
      <div className="bg-slate-100 h-full relative z-[0] flex-1 flex-col flex">
        <PageTitle
          title="Settings"
          content="Add hospitals and its related branches"
        />
        <div className="flex flex-wrap flex-1">
          <div className="w-1/5 h-full overflow-hidden">
            <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)] 2xl:px-[30px] px-5 2xl:py-[30px] py-5 h-[calc(100dvh-176px)] overflow-auto">
                <MenuItem
                  onClick={handleHospitalSettingClick}
                  className={
                    activePage === "hospital-setting"
                      ? "bg-primary text-white"
                      : ""
                  }
                >
                  Hospitals
                </MenuItem>
                <MenuItem
                  onClick={handleUsersClick}
                  className={
                    activePage === "user-setting" ? "bg-primary text-white" : ""
                  }
                >
                  Users
                </MenuItem>
            </div>
          </div>
          {activePage === "hospital-setting" ? (
            <HospitalSettings />
          ) : (
            <UserSettings />
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
